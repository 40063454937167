import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import MaterialTable from "material-table";
import CircleLoader from "react-spinners/ClipLoader";
import { Alert } from 'reactstrap';
import { Button, Modal, Grid, Paper, TextField, Checkbox, IconButton, Snackbar } from '@material-ui/core';
import { Close } from "@material-ui/icons";
import csv from './importExample.csv';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
//import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import moment from "moment";
import MuiAlert from '@material-ui/lab/Alert';


function AlertM(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

//Customerlist
const useStyles = makeStyles(styles);
export default class TableList extends React.Component {
  constructor(props) {
    super(props);
    //init customerdata
    var rows = new Array;
    for (let i = 0; i < this.props.customerdata.length; i++) {
      const element = this.props.customerdata[i];
      rows[i] = { id: element.id, customer_id: element.customer_id, active: element.active, name: element.name, surname: element.surname, email: element.email, birth: element.birth }
    }

    //init state
    this.state = {
      columns: [
        /*{
          title: "ID",
          field: "id",
          editable: 'never',
          type: "numeric"
        }, */{
          title: "Customer ID",
          field: "customer_id",
          type: "numeric"
        }, {
          title: "Active",
          field: "active",
          type: "numeric"
        }, {
          title: "Name",
          field: "name"
        }, {
          title: "Surname",
          field: "surname"
        }, {
          title: "Email",
          field: "email"
        }, {
          title: "birth",
          field: "birth",
          type: "date",

        }
      ],
      data: rows,
      openmodal: false,
      loading: false,
      buttontext: "Add Customer",
      errormail: false,
      cidval: "",
      openSnackbar: false,
      snackbarMsg: "",
      snackbarSeverity: ""
    }
  }

  //function to close Modal
  closeModal() {
    this.setState({
      openmodal: false,
      cidval: ""
    });
  }

  //render Customerlist
  render() {
    let thistemp = this;
    function handleSnackbarClose() {
      thistemp.setState({ openSnackbar: false, snackbarMsg: "" })
    }

    function setSnackbar(open, msg, severity) {
      thistemp.setState({ openSnackbar: open, snackbarMsg: msg, snackbarSeverity: severity })
    }

    return (
      <>
        <Snackbar open={this.state.openSnackbar} autoHideDuration={10000} onClose={handleSnackbarClose}>
          <AlertM onClose={handleSnackbarClose} severity={this.state.snackbarSeverity} >
            {this.state.snackbarMsg}
          </AlertM>
        </Snackbar>
        <Modal
          style={{ top: "10%", margin: "auto" }}
          open={this.state.openmodal}
          onClose={() => { }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Paper style={{ padding: "20px" }}>
                <IconButton style={{ position: "absolute", top: "10px", right: "10px" }}>
                  <Close onClick={() => {
                    this.closeModal();
                  }}></Close>
                </IconButton>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <h2>Add a new Customer</h2>
                    <h4>Here you can add new Customers. Make sure all required fields(*) are filled out.</h4>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField variant="outlined" fullWidth label="Name" id="name"></TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField variant="outlined" fullWidth label="Surname" id="surname"></TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField variant="outlined" fullWidth label="E-mail(*)" id="email" type="email" error={this.state.errormail}></TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField variant="outlined" fullWidth label="birth(DD.MM.YYYY)" id="birth"></TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField variant="outlined" fullWidth label={"Customer ID (" + this.props.company.login_code_length + " digits)"} id="customer_id" value={this.state.cidval} onChange={(e) => {
                      var val = e.target.value;
                      if (!isNaN(val)) {
                        this.setState({
                          cidval: val
                        });
                      }
                    }}></TextField>
                  </Grid>
                  <Grid item xs={12}>
                    Active: <Checkbox id="active" defaultChecked={true}></Checkbox>
                  </Grid>
                  <Alert color="warning" id="alertwines" style={{ display: "none" }}>
                    Placeholder
                  </Alert>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      //return if loading
                      if (this.state.loading) {
                        return;
                      }
                      //set loading state
                      this.setState({
                        loading: true,
                        buttontext: "Loading..."
                      });

                      //init newData obj
                      var newData = {};
                      newData.name = document.getElementById("name").value;
                      newData.surname = document.getElementById("surname").value;
                      newData.email = document.getElementById("email").value;
                      newData.birth = document.getElementById("birth").value;
                      newData.customer_id = document.getElementById("customer_id").value;
                      newData.active = document.getElementById("active").checked;

                      //check customerid
                      for (let i = 0; i < this.state.data.length; i++) {
                        const element = this.state.data[i];
                        if (element.customer_id == newData.customer_id) {
                          document.getElementById("alertwines").style.display = "block";
                          document.getElementById("alertwines").innerHTML = "The Customer ID is already taken.";
                          setTimeout(() => {
                            try {
                              document.getElementById("alertwines").style.display = "none";
                            } catch (error) {

                            }
                          }, 10000);

                          return;
                        }
                      }

                      //check e-mail
                      if (newData.email.length == 0) {
                        setTimeout(() => {
                          this.setState({
                            errormail: false
                          });
                        }, 10000);
                        this.setState({
                          loading: false,
                          buttontext: "Add Customer",
                          errormail: true
                        });
                        return;
                      }
                      //check name
                      if (newData.name.length == 0) {
                        newData.name = "x";
                      }
                      //check surname
                      if (newData.surname.length == 0) {
                        newData.surname = "x";
                      }
                      //check birth
                      if (newData.birth.length == 0) {
                        newData.birth = undefined;
                      }
                      //check customer_id
                      if (newData.customer_id.length == 0) {
                        newData.customer_id = undefined;
                      } else {
                        //check customerist for init and length
                        if (isNaN(parseInt(newData.customer_id)) || newData.customer_id.length != this.props.company.login_code_length) {
                          document.getElementById("alertwines").style.display = "block";
                          document.getElementById("alertwines").innerHTML = "The ID you entered seems not to be valid.";
                          setTimeout(() => {
                            try {
                              document.getElementById("alertwines").style.display = "none";
                            } catch (error) {

                            }
                          }, 10000);
                          this.setState({
                            loading: false,
                            buttontext: "Add Customer"
                          });
                          return;
                        }
                      }

                      //add Customer to table and db
                      global.addCustomer(newData, false);
                      setSnackbar(true, "Customer added successfully", "success");
                      setTimeout(() => {
                        var arr = new Array;
                        for (let i = 0; i < this.props.customerdata.length; i++) {
                          const element = this.props.customerdata[i];
                          arr[i] = { id: element.id, customer_id: element.customer_id, active: element.active, name: element.name, surname: element.surname, email: element.email, birth: element.birth }
                        }
                        this.closeModal();
                        this.setState({
                          data: arr,
                          loading: false,
                          buttontext: "Add Customer"
                        })
                      }, 3000);
                    }}>{this.state.buttontext}</Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Modal>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card plain>
              <CardHeader plain color="primary">
                <h4 style={styles.cardTitleWhite}>
                  Customers
                </h4>
                <p style={styles.cardCategoryWhite}>
                  Here is a List of all Customers
                </p>
              </CardHeader>
              <CardBody>
                <Button variant="outlined" color="primary" onClick={() => {
                  this.setState({
                    openmodal: true
                  });
                }}>
                  Add a new Customer
                </Button>
                <Alert color="warning" id="alertcustomer" style={{ display: "none" }}>
                  Placeholder
                </Alert>
                <MaterialTable
                  columns={this.state.columns}
                  data={this.state.data}
                  title="Customers"
                  options={{
                    exportButton: true,
                    selection: true,
                    pageSize: 10
                  }}
                  actions={[
                    {//Delete action
                      tooltip: 'Remove All Selected Users',
                      icon: 'delete',
                      //onClick: (evt, data) => alert('You want to delete ' + data.length + ' rows')
                      onClick: (evt, data) => {
                        for (let i = 0; i < data.length; i++) {
                          const element = data[i];
                          // console.log("id: " + data[i].id + " customerID: " + element.customer_id)
                          global.deleteCustomer(data[i].id)
                        }

                        setSnackbar(true, "Successfully deleted " + data.length + " customers", "success");

                        var arr = new Array;
                        for (let i = 0; i < this.props.customerdata.length; i++) {
                          const element = this.props.customerdata[i];
                          arr[i] = { id: element.id, customer_id: element.customer_id, active: element.active, name: element.name, surname: element.surname, email: element.email, birth: element.birth }
                        }
                        this.setState({
                          data: arr
                        })
                      }
                    }
                  ]}
                  editable={{
                    //row edit
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          {
                            //check customerid
                            for (let i = 0; i < this.state.data.length; i++) {
                              const element = this.state.data[i];
                              if (element.id != newData.id) {
                                if (element.customer_id == newData.customer_id) {
                                  document.getElementById("alertcustomer").style.display = "block";
                                  document.getElementById("alertcustomer").innerHTML = "The Customer ID is already taken.";
                                  setTimeout(() => {
                                    try {
                                      document.getElementById("alertcustomer").style.display = "none";
                                    } catch (error) {

                                    }
                                  }, 10000);
                                  reject();
                                  return;
                                }
                              }
                            }
                            if (newData.customer_id.toString().length != this.props.company.login_code_length || isNaN(parseInt(newData.customer_id))) {
                              document.getElementById("alertcustomer").style.display = "block";
                              document.getElementById("alertcustomer").innerHTML = "The Customer ID needs to be " + this.props.company.login_code_length + " characters long.";
                              setTimeout(() => {
                                try {
                                  document.getElementById("alertcustomer").style.display = "none";
                                } catch (error) {

                                }
                              }, 10000);
                              reject();
                              return;
                            }

                            //check active
                            if (newData.active != 1 && newData.active != 0 || newData.active.toString().length != 1) {
                              document.getElementById("alertcustomer").style.display = "block";
                              document.getElementById("alertcustomer").innerHTML = "Active needs to be 0 or 1.";
                              setTimeout(() => {
                                try {
                                  document.getElementById("alertcustomer").style.display = "none";
                                } catch (error) {

                                }
                              }, 10000);
                              reject();
                              return;
                            }

                            //update customer
                            global.updateCustomer(newData);
                            setSnackbar(true, "Successfully updated customer", "success");

                            try {
                              document.getElementById("alertcustomer").style.display = "none";
                            } catch (error) {

                            }

                            //add customer to table
                            var arr = new Array;
                            for (let i = 0; i < this.props.customerdata.length; i++) {
                              const element = this.props.customerdata[i];
                              arr[i] = { id: element.id, customer_id: element.customer_id, active: element.active, name: element.name, surname: element.surname, email: element.email, birth: element.birth }
                            }
                            this.setState({
                              data: arr
                            })
                          }
                          resolve();
                        }, 1000);
                      }),
                    //delete on row
                    onRowDelete: oldData =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          {
                            //delete db
                            setSnackbar(true, "Successfully deleted customer", "success");
                            global.deleteCustomer(oldData.id)
                            var arr = new Array;
                            //delete from table
                            for (let i = 0; i < this.props.customerdata.length; i++) {
                              const element = this.props.customerdata[i];
                              arr[i] = { id: element.id, customer_id: element.customer_id, active: element.active, name: element.name, surname: element.surname, email: element.email, birth: element.birth }
                            }
                            this.setState({
                              data: arr
                            })
                          }
                          resolve()
                        }, 1000)
                      }),
                  }}
                />
              </CardBody>
            </Card>
          </GridItem>
          <FileUploader customers={this.props.customerdata} company={this.props.company} setSnackbar={setSnackbar}></FileUploader>
        </GridContainer>
      </>
    );
  }
}

//Import CSV Component
function FileUploader(props) {
  const [alertshow, setAlertShow] = React.useState(false);
  const [importdata, setImportData] = React.useState(undefined);
  const [alerttext, setAlerttext] = React.useState("");
  const [loadershow, setLoaderShow] = React.useState(false);
  const [decideshow, setDecideShow] = React.useState(false);
  const [editImportField, setEditImportField] = React.useState(undefined);

  //update data inline edit
  function updateImportData(index, field, value) {
    if (field === "customer_id" && isNaN(value)) {
      return;
    }
    let importdatac = [...importdata];
    importdatac[index][field] = value;
    setImportData(importdatac);
  }

  function validateArr(customerarr) {
    const errors = [];
    for (let i = 0; i < customerarr.length; i++) {
      const customer = customerarr[i];

      let f = false;
      for (let i = 0; i < props.customers.length; i++) {
        const c2 = props.customers[i];

        if (customer.customer_id == c2.customer_id) {
          errors.push("CustomerId duplicate")
          f = true;
        }
      }
      if (!f) {
        if (customer.customer_id === undefined) {
          errors.push("Missing field: CustomerID")
        } else if (customer.name === undefined) {
          errors.push("Missing field: Name")
        } else if (customer.surname === undefined) {
          errors.push("Missing field: Surname")
        } else if (customer.email === undefined) {
          errors.push("Missing field: Email")
        } else if (customer.birth === undefined) {
          errors.push("Missing field: Birth")
        } else if (customer.customer_id.length !== props.company.login_code_length) {
          errors.push("CustomerId length")
        } else if (isNaN(customer.customer_id)) {
          errors.push("CustomerId is not a Number")
        } else if (!validateEmail(customer.email)) {
          errors.push("Invalid e-mail")
        } else if (!validateEmail(customer.email)) {
          errors.push("Invalid e-mail")
        } else {
          let birth = customer.birth.split(".");
          if (birth.length != 3) {
            errors.push("Invalid date")
          } else if (isNaN(birth[0])) {
            errors.push("Invalid day")
          } else if (isNaN(birth[1])) {
            errors.push("Invalid month")
          } else if (isNaN(birth[2])) {
            errors.push("Invalid year")
          } else if (!moment(customer.birth, "DD.MM.YYYY", true).isValid()) {
            errors.push("Invalid date")
          }
        }
      }
    }

    return errors;
  }

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  // console.log(importdata);

  return (<>
    <Grid container spacing={3} style={{ marginLeft: "25px" }}>
      <Grid item xs={12}>
        <form method="get" action={csv}>
          <button type="submit">Download example CSV</button>
        </form>
      </Grid>
      <Grid item xs={12}>
        <input onChange={upload} id="csv" type="file"></input>
        {loadershow && <CircleLoader></CircleLoader>}
        {alertshow &&
          <Alert color="warning" id="feedbackimport" style={{ marginLeft: "30px" }}>
            {alerttext}
          </Alert>}
      </Grid>
      <Grid item xs={12}>
        {decideshow &&
          <>
            Do you want to upload the following data?
            <IconButton onClick={() => {
              setAlertShow(false);
              setImportData(undefined);
              setAlerttext("");
              setLoaderShow(false);
              setDecideShow(false);
              try {
                document.getElementById("csv").value = "";
              } catch (error) {

              }
            }}>
              <CloseIcon></CloseIcon>
            </IconButton>
            <IconButton onClick={() => {
              if (validateArr(importdata).length === 0) {
                props.setSnackbar(true, "Successfully added customers", "success");
                setLoaderShow(true);
                global.addCustomers(importdata);
                setTimeout(() => {
                  setAlertShow(false);
                  setImportData(undefined);
                  setAlerttext("");
                  setLoaderShow(false);
                  setDecideShow(false);
                }, 1000);
              } else {
                props.setSnackbar(true, "Error while adding customers", "error");
                alert("errors detected: \n" + validateArr(importdata).toString())
              }
            }}>
              <CheckIcon></CheckIcon>
            </IconButton>
          </>}
      </Grid>
      <Grid item xs={12}>
        {importdata !== undefined && <Paper>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Customer ID</TableCell>
                <TableCell align="right">Name</TableCell>
                <TableCell align="right">Surname</TableCell>
                <TableCell align="right">E-Mail</TableCell>
                <TableCell align="right">birth</TableCell>
                <TableCell align="right">Error</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {importdata.map((row, i) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row" onClick={() => setEditImportField(i + ".customer_id")} align="left">{editImportField === i + ".customer_id" ? <TextField onBlur={() => setEditImportField(undefined)} autoFocus value={row.customer_id} onChange={(e) => { updateImportData(i, "customer_id", e.target.value) }}></TextField> : <p >{row.customer_id}</p>}</TableCell>
                  <TableCell onClick={() => setEditImportField(i + ".name")} align="right">{editImportField === i + ".name" ? <TextField onBlur={() => setEditImportField(undefined)} autoFocus value={row.name} onChange={(e) => { updateImportData(i, "name", e.target.value) }}></TextField> : <p >{row.name}</p>}</TableCell>
                  <TableCell onClick={() => setEditImportField(i + ".surname")} align="right">{editImportField === i + ".surname" ? <TextField onBlur={() => setEditImportField(undefined)} autoFocus value={row.surname} onChange={(e) => { updateImportData(i, "surname", e.target.value) }}></TextField> : <p >{row.surname}</p>}</TableCell>
                  <TableCell onClick={() => setEditImportField(i + ".email")} align="right">{editImportField === i + ".email" ? <TextField onBlur={() => setEditImportField(undefined)} autoFocus value={row.email} onChange={(e) => { updateImportData(i, "email", e.target.value) }}></TextField> : <p >{row.email}</p>}</TableCell>
                  <TableCell onClick={() => setEditImportField(i + ".birth")} align="right">{editImportField === i + ".birth" ? <TextField onBlur={() => setEditImportField(undefined)} autoFocus value={row.birth} onChange={(e) => { updateImportData(i, "birth", e.target.value) }}></TextField> : <p >{row.birth}</p>}</TableCell>
                  <TableCell align="right">{validateArr([row]).toString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>}
      </Grid>
    </Grid>
  </>)

  function upload() {
    try {
      var fileInput = document.getElementById("csv");

      var reader = new FileReader();

      reader.onload = function () {
        setLoaderShow(true);
        var csv = reader.result;
        var lines = csv.split("\n");
        var result = [];
        var headers = lines[0].split(";");

        for (let i = 0; i < headers.length; i++) {
          const element = headers[i];
          headers[i] = element.replace("\r", "")
        }

        if (!headers.includes("email")) {
          setAlertShow(true);
          setAlerttext("The CSV file you provided includes no email field.");
          setTimeout(() => {
            try {
              setAlertShow(false);
            } catch (error) {

            }
          }, 10000);
          setLoaderShow(false);
          return;
        }

        var mailindex = headers.indexOf("email");
        for (var i = 1; i < lines.length; i++) {
          var obj = {};
          var currentline = lines[i].split(";");

          try {
            if (!currentline[mailindex].length == 0) {
              //if (validateEmail(currentline[mailindex])) {
              for (var j = 0; j < headers.length; j++) {
                obj[headers[j]] = currentline[j];
              }
              result.push(obj);
              //}
            }
          } catch (error) {

          }
        }

        function validateEmail(email) {
          const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(String(email).toLowerCase());
        }

        /*for (let i = 0; i < result.length; i++) {
          try {
            const element = result[i];
            var parts = element.birth.split(".");
            element.birth = parts[2] + "." + parts[1] + ".19" + parts[0];
          } catch (error) {

          }
        }*/
        for (let i = 0; i < result.length; i++) {
          const element = result[i];
          Object.keys(element).map(k => element[k.trim()] = element[k].trim());
        }
        setImportData(result);
        setLoaderShow(false);
        setDecideShow(true);
      };
      reader.readAsBinaryString(fileInput.files[0]);
    } catch (error) {

    }
  }
}
