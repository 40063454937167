import { Button, Dialog, DialogTitle, Grid, MenuItem, Select, TextField, Snackbar } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import MaterialTable from 'material-table';
import { func } from 'prop-types';
import React from 'react'
import AddUserAccount from './AddUserAccount';
import MuiAlert from '@material-ui/lab/Alert';
var axios = require("axios");

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function UserManagment(props) {
    const [users, setUsers] = React.useState(undefined);
    const [openNewUser, setOpenNewUser] = React.useState(false);
    const [availablecompanies, setAvailableCompanies] = React.useState(undefined);
    const [availablebranches, setAvailableBranches] = React.useState(undefined);

    //Change Password
    const [passwordModalData, setPasswordModalData] = React.useState(undefined);
    const [password, setPassword] = React.useState("");
    const [password2, setPassword2] = React.useState("");

    //edit user
    const [editmodaldata, setEditModalData] = React.useState(undefined);

    //snackbar
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [snackbarMsg, setSnackbarMsg] = React.useState("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState("");


    function handleSnackbarClose() {
        setOpenSnackbar(false);
        setSnackbarMsg("");
    }

    function setSnackbar(open, msg, severity) {
        setOpenSnackbar(open);
        setSnackbarMsg(msg);
        setSnackbarSeverity(severity);
    }

    function closePasswordModal() {
        setPasswordModalData(undefined);
        setPassword("");
        setPassword2("");
    }
    function closeEditModal() {
        setEditModalData(undefined);
        setPassword("");
        setPassword2("");
    }

    let availableranks = ["Excecutive", "Manager", "Administrator", "Super-Admin"];
    if (availablecompanies == undefined) {
        fetch(global.localip + "/companies/", { headers: { "x-access-token": global.token } })
            .then(response => response.json())
            .then(responseJson => {
                let topush = [];
                for (let i = 0; i < responseJson.length; i++) {
                    const element = responseJson[i];
                    if (element.id === props.company_id || props.company_id == 0) {
                        topush.push(element);
                    }
                }

                setAvailableCompanies(topush);
            });

        return <>Loading Companies</>;
    }

    if (availablebranches == undefined) {
        fetch(global.localip + "/branches/" + props.company_id, { headers: { "x-access-token": global.token } })
            .then(response => response.json())
            .then(responseJson => {
                setAvailableBranches(responseJson);
            });

        return <>Loading Branches</>;
    }

    for (let i = availableranks.length - 1; i >= 0; i--) {
        if (i > props.rank) {
            availableranks.pop();
        }
    }


    function fetchUsers() {
        fetch(global.localip + "/login/" + props.company_id, { headers: { "x-access-token": global.token } })
            .then(response => response.json())
            .then(responseJson => {
                responseJson = responseJson.filter(function (item) {
                    return item.rank <= props.rank;
                })
                let topush = [];
                for (let i = 0; i < responseJson.length; i++) {
                    const element = responseJson[i];
                    if (element.rank !== -1) {
                        if (props.rank >= 3) {
                            topush.push(element);
                        } else if (props.rank >= 2) {
                            if (element.rank <= 2) {
                                topush.push(element);
                            }
                        } else if (props.rank >= 1) {
                            if (element.rank <= 1 && element.branche_id == props.branch_id) {
                                topush.push(element);
                            }
                        }
                    }
                }

                setUsers(topush);
            });
    }

    if (users == undefined) {
        fetchUsers();
        return <>Loading Users</>;
    }

    function updateUser(userid, field, value) {
        let usersc = [...users];
        for (let u = 0; u < usersc.length; u++) {
            const user = usersc[u];
            if (userid == user.id) {
                if (user.rank <= props.rank) {
                    user[field] = value;
                }

                axios.post(global.localip + "/login/" + user.id, {
                    ...user, created_at: undefined, updated_at: undefined, tableData: undefined, id: undefined
                }, { headers: { "x-access-token": global.token } })
                    .then((response) => {

                    }, (error) => {
                        console.log(error);
                    });
            }
        }
        setUsers(usersc);
    }

    function SelectRank(props) {
        const rank = props.rank;

        return <Select value={rank} onChange={(e) => {
            if (e.target.value == 3) {
                updateUser(props.userid, "company_id", 0);
                updateUser(props.userid, "branche_id", 0);
            } else if (e.target.value == 2) {
                updateUser(props.userid, "company_id", 1);
                updateUser(props.userid, "branche_id", 0);
            } else if (e.target.value < 2) {
                updateUser(props.userid, "company_id", 1);
                updateUser(props.userid, "branche_id", 1);
            }

            updateUser(props.userid, "rank", e.target.value)

        }}>
            {availableranks.map((rankc, i) => {
                return <MenuItem key={i} value={i}>{rankc}</MenuItem>
            })}
        </Select>
    }

    function SelectCompany(props) {
        const company = props.company;
        const ownRank = props.ownRank;
        const rank = props.rank;

        return <Select disabled={ownRank < 3 || rank === 3} value={company} onChange={(e) => {
            updateUser(props.userid, "company_id", e.target.value)
            updateUser(props.userid, "branche_id", 0)
            availablebranches.map((branchc) => {
                if (rank < 2) {
                    if (branchc.company_id == e.target.value) {
                        updateUser(props.userid, "branche_id", branchc.id)
                    }
                }
            })
        }}>
            <MenuItem value={0} disabled={rank !== 3}>All</MenuItem>
            {ownRank < 3 || rank === 3 ? <MenuItem value={0} disabled>Keine gewählt</MenuItem> : null}
            {availablecompanies.map((companyc) => {
                return <MenuItem key={companyc.id} value={companyc.id}>{companyc.name} ({companyc.id})</MenuItem>
            })}
        </Select>
    }

    function SelectBranch(props) {
        const branch = props.branch;
        const ownRank = props.ownRank;
        const rank = props.rank;

        return <Select disabled={ownRank < 2 || rank >= 2} value={branch} onChange={(e) => updateUser(props.userid, "branche_id", e.target.value)}>
            <MenuItem value={0} disabled>All</MenuItem>
            {ownRank < 2 || rank === 3 ? <MenuItem value={0} disabled>Keine gewählt</MenuItem> : null}
            {availablebranches.map((branchc) => {
                if (branchc.company_id == props.company) {
                    return <MenuItem key={branchc.id} value={branchc.id}>{branchc.name} ({branchc.id})</MenuItem>
                }
            })}
        </Select>
    }

    // console.log(editmodaldata);

    return (
        <div>
            <GridContainer>
                <Snackbar open={openSnackbar} autoHideDuration={10000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} >
                        {snackbarMsg}
                    </Alert>
                </Snackbar>
                <GridItem xs={12} sm={12} md={12}>
                    <Card plain>
                        <CardHeader plain color="primary">
                            <h4>
                                Users
                            </h4>
                            <p>
                                here you can add, edit and delete users
                            </p>
                        </CardHeader>
                        <CardBody>
                            <Button onClick={() => setOpenNewUser(true)} variant="outlined" color="primary">Add New User</Button>
                            <br></br>
                            <Dialog onClose={() => setOpenNewUser(false)} open={openNewUser}>
                                <DialogTitle>Add new User</DialogTitle>
                                <div style={{ margin: "30px" }}>
                                    <AddUserAccount doneFunc={() => {
                                        setOpenNewUser(false)
                                        fetchUsers();
                                        setSnackbar(true, "Successfully added User", "success")
                                    }} rank={props.rank} company_id={props.company_id} branch_id={props.branch_id}></AddUserAccount>
                                </div>
                            </Dialog>
                            <Dialog onClose={closeEditModal} open={editmodaldata !== undefined} spacing={2}>
                                {editmodaldata === undefined ? null : <>
                                    <DialogTitle>Edit User: {editmodaldata.email}</DialogTitle>
                                    <div style={{ margin: "30px" }}>
                                        <AddUserAccount doneFunc={() => {
                                            setEditModalData(undefined)
                                            fetchUsers();
                                            setSnackbar(true, "Successfully edited User", "success")
                                        }} rank={props.rank} company_id={props.company_id} branch_id={props.branch_id} edit email={editmodaldata.email} permitted_company={editmodaldata.company_id} permitted_branch={editmodaldata.branche_id} ranknew={editmodaldata.rank} editid={editmodaldata.id}></AddUserAccount>
                                    </div>
                                </>}
                            </Dialog>
                            <Dialog onClose={closePasswordModal} open={passwordModalData !== undefined} spacing={2}>
                                {passwordModalData === undefined ? null : <>
                                    <DialogTitle>Change password for: {passwordModalData.email}</DialogTitle>
                                    <div style={{ margin: "15px" }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <TextField variant="outlined" label="Password" value={password} onChange={(e) => setPassword(e.target.value)}></TextField>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField variant="outlined" label="Repeat password" value={password2} onChange={(e) => setPassword2(e.target.value)}></TextField>
                                            </Grid><br></br>
                                            <Grid item xs={12}>
                                                <Button color="error" variant="outlined" onClick={() => {
                                                    if (password === password2) {
                                                        updateUser(passwordModalData.id, "password", password);
                                                        setSnackbar(true, "Successfully changed user password", "success")
                                                        closePasswordModal();
                                                    } else {
                                                        setSnackbar(true, "Passwords do not match", "error")
                                                    }
                                                }}>Change Password</Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </>}
                            </Dialog>
                            <MaterialTable
                                title="Userlist"
                                columns={[
                                    { title: 'E-Mail', field: 'email' },
                                    { title: 'Rank', field: 'rank', render: (rowData) => <SelectRank rank={rowData.rank} userid={rowData.id}></SelectRank> },
                                    { title: 'Company', field: 'company_id', render: (rowData) => <SelectCompany ownRank={props.rank} rank={rowData.rank} company={rowData.company_id} userid={rowData.id}></SelectCompany> },
                                    { title: 'Branch', field: 'branche_id', render: (rowData) => <SelectBranch ownRank={props.rank} rank={rowData.rank} company={rowData.company_id} branch={rowData.branche_id} userid={rowData.id}></SelectBranch> }
                                ]}
                                data={users}
                                actions={[
                                    {
                                        icon: 'delete',
                                        tooltip: 'Delete User',
                                        onClick: (event, rowData) => {
                                            axios.delete(global.localip + "/login/" + rowData.id, { headers: { "x-access-token": global.token } })
                                                .then((response) => {
                                                    fetchUsers();
                                                    setSnackbar(true, "Successfully deleted user", "success")
                                                }, (error) => {
                                                    setSnackbar(true, "Error deleting user", "error")
                                                    console.log(error);
                                                });
                                        }
                                    },
                                    {
                                        icon: "lock",
                                        tooltip: 'Change Password',
                                        onClick: (event, rowData) => {
                                            setPasswordModalData(rowData);
                                        }
                                    },
                                    {
                                        icon: "edit",
                                        tooltip: 'Edit User',
                                        onClick: (event, rowData) => {
                                            setEditModalData(rowData);
                                        }
                                    }
                                ]}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    )
}

export default UserManagment
