import React from 'react'
import BranchView from './BranchView';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { ExpandMore } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import Device from './Device';
import BranchSettings from 'views/BranchSettings/BranchSettings';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';


function AllBranchesForCompany(props) {
    const [branches, setBranches] = React.useState(undefined);
    var company_id = props.company_id;

    if (company_id == undefined) {
        return "No Company ID";
    }


    function fetchBranches() {
        fetch(global.localip + "/branches/" + company_id, {headers: {"x-access-token": global.token}})
            .then(response => response.json())
            .then(responseJson => {
                setBranches(responseJson);
            });
    }
    if (branches == undefined) {
        fetchBranches();
        return <>Loading Branches</>;
    }



    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card plain>
                        <CardHeader plain color="primary">
                            <h4>
                                Branches
                            </h4>
                            <p>
                                Manage Branches & Settings
                            </p>
                        </CardHeader>
                        <CardBody>
                            {branches.map((branch, index) => (
                                <Accordion key={branch}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore></ExpandMore>}
                                    >
                                        <Typography>{branch.name} ({branch.id})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <BranchView branch_id={branch.id} company_id={company_id} wines={props.wines} images={props.images} rank={props.rank} fetchBranches={fetchBranches}></BranchView>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    )
}

export default AllBranchesForCompany
