import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Grid, TextField } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import APIAttributes from './APIAttributes';


//API Settings in Company
function APISettings(props) {
    let changefieldCompany = props.changefieldCompany;
    let changeSettings = props.changeSettings;
    let company = props.company;

    // console.log("gffh");
    // console.log(company);

    return (
        <>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore></ExpandMore>}>API-Settings</AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField type="number" variant="outlined" multiline fullWidth label="API Token" disabled value={company.api_token}></TextField>
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMore></ExpandMore>}>Wines</AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        <APIAttributes type="wines" company={company}></APIAttributes>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMore></ExpandMore>}>Customers</AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMore></ExpandMore>}>Cooler</AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMore></ExpandMore>}>Device</AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMore></ExpandMore>}>Pours</AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMore></ExpandMore>}>Tap</AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid> */}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default APISettings
