import React from 'react'

function UserSettings(props) {
    const [users, setUsers] = React.useState(undefined);
    // console.log(props);

    if (users == undefined) {
        if (props.rank >= 2) {
            fetch(global.localip + "/getAllUsers/" + props.company_id, { headers: { "x-access-token": global.token } })
                .then(response => response.json())
                .then(responseJson => {
                    setUsers(responseJson);
                });

            return <>Loading Customers</>;
        } else {
            fetch(global.localip + "/getAllUsers/" + props.company_id + "/" + props.branch_id, { headers: { "x-access-token": global.token } })
                .then(response => response.json())
                .then(responseJson => {
                    setUsers(responseJson);
                });

            return <>Loading Customers</>;
        }
    }
    // console.log(users);
    return (
        <div>
            Show all Users and Dependencies
        </div>
    )
}

export default UserSettings
