import { Grid, TextField, Select, MenuItem, FormControl, InputLabel, Button, Snackbar } from '@material-ui/core';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import APISettings from 'views/APISettings/APISettings';
var axios = require("axios");

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

//Fetches Settings of a Company and provides a view to configure these.
function CompanySettings(props) {
    const [company, setCompany] = React.useState(undefined);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [snackbarMsg, setSnackbarMsg] = React.useState("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState("");

    function handleSnackbarClose() {
        setOpenSnackbar(false);
        setSnackbarMsg("");
    }

    function setSnackbar(open, msg, severity) {
        setOpenSnackbar(open);
        setSnackbarMsg(msg);
        setSnackbarSeverity(severity);
    }

    //ensure company_id is number / not object
    let company_id = props.company_id;
    if (company_id.id !== undefined) {
        company_id = company_id.id;
    }

    //fetches Company Settings
    function fetchCompanyData() {
        fetch(global.localip + "/companies/", { headers: { "x-access-token": global.token } })
            .then(response => response.json())
            .then(responseJson => {
                for (let i = 0; i < responseJson.length; i++) {
                    const element = responseJson[i];
                    if (element.id === company_id) {
                        setCompany(element);
                    }
                }
            });
    }

    //fetch Companydata and set state
    if (company === undefined) {
        fetchCompanyData();
        return "Loading";
    } else {
        if (company.api_attributes === undefined || company.api_attributes === "" || company.api_attributes === "0") {
            company.api_attributes = JSON.stringify({})
        }
    }

    //change Field of Company
    function changefieldCompany(field, value) {
        let companyc = JSON.parse(JSON.stringify(company));
        companyc[field] = value;

        setCompany(companyc);
    }

    //update Settings call
    function changeSettings() {
        axios.post(global.localip + "/companies/" + company_id, {
            ...company, created_at: undefined, updated_at: undefined, id: undefined
        }, { headers: { "x-access-token": global.token } })
            .then((response) => {
                setSnackbar(true, "Companysettings changed successfully", "success")
                // console.log(response);
            }, (error) => {
                setSnackbar(true, "Companysettings error", "error")
                console.log(error);
            });
    }


    return (
        <GridContainer>
            <Snackbar open={openSnackbar} autoHideDuration={10000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} >
                    {snackbarMsg}
                </Alert>
            </Snackbar>
            <GridItem xs={12} sm={12} md={12}>
                <Card plain>
                    <CardHeader plain color="primary">
                        <h4>
                            Companysettings
                        </h4>
                        <p>
                            Setup your company
                        </p>
                    </CardHeader>
                    <CardBody>
                        <Grid container spacing={4}>
                            <Grid item xs={3}>
                                <TextField variant="outlined" fullWidth label="Companyname" value={company.name} onChange={(e) => {
                                    changefieldCompany("name", e.target.value);
                                }}></TextField>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField variant="outlined" multiline fullWidth label="Description" value={company.description} onChange={(e) => {
                                    changefieldCompany("description", e.target.value);
                                }}></TextField>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField variant="outlined" type={"number"} fullWidth label="Customer Id Länge" value={company.login_code_length} onChange={(e) => {
                                    changefieldCompany("login_code_length", e.target.value);
                                }}></TextField>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField type="number" variant="outlined" multiline fullWidth label="Customer tasting limit" value={company.tasting_amount} onChange={(e) => {
                                    changefieldCompany("tasting_amount", e.target.value);
                                }}></TextField>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField type="number" variant="outlined" multiline fullWidth label="Mail Origin" value={company.from_mail} onChange={(e) => {
                                    changefieldCompany("from_mail", e.target.value);
                                }}></TextField>
                            </Grid>
                            {props.rank === 3 ? <Grid item xs={2}>
                                <Button fullWidth variant="outlined" color="secondary" onClick={() => {
                                    if (window.confirm("Are you sure to delete the company?")) {
                                        if (window.confirm("Are you really really sure to delete the company?")) {
                                            axios.delete(global.localip + "/companies/" + company_id, { headers: { "x-access-token": global.token } })
                                                .then((response) => {
                                                    if (props.fetchCompanies !== undefined) {
                                                        props.fetchCompanies();
                                                    }
                                                }, (error) => {
                                                    console.log(error);
                                                });
                                        }
                                    }
                                }}>Delete Company</Button>
                            </Grid> : null}
                            {props.rank >= 2 ? <Grid item xs={12}>
                                <APISettings changefieldCompany={changefieldCompany} changeSettings={changeSettings} company={company}>

                                </APISettings>
                            </Grid> : null}
                            <Grid item xs={12}>
                                <Button color="primary" onClick={changeSettings} variant="outlined">Change Settings</Button>
                            </Grid>
                        </Grid>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}

export default CompanySettings
