import React from 'react'
import Icon from '@material-ui/core/Icon';
import Bar from '@material-ui/icons/LocalBar';
import { Link } from 'react-router-dom';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import CardFooter from 'components/Card/CardFooter';
import Stars from '@material-ui/icons/Stars';
import User from '@material-ui/icons/SupervisedUserCircleSharp';
import CircleLoader from 'react-spinners/ClipLoader';

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { makeStyles } from '@material-ui/core/styles';
import AdminView from '../SuperAdmin/AdminView.js';
import { MenuItem, Select } from '@material-ui/core';
import CardBody from 'components/Card/CardBody';
import DashboardSuperadminCompanyRow from './DashboardSuperadminCompanyRow.js';
import { Business, LibraryBooks, People } from '@material-ui/icons';

const useStyles = makeStyles(styles);

function DashboardSuperadmin(props) {
    const [companies, setCompanies] = React.useState(undefined);
    const [branches, setBranches] = React.useState(undefined);
    const [devices, setDevices] = React.useState(undefined);
    const [ratings, setRatings] = React.useState(undefined);
    const [customers, setCustomers] = React.useState(undefined);
    const classes = useStyles();


    if (companies === undefined) {
        fetch(global.localip + "/companies/", {headers: {"x-access-token": global.token}})
            .then(response => response.json())
            .then(responseJson => {
                setCompanies(responseJson);
            });
        return "Loading";
    }

    if (branches === undefined) {
        fetch(global.localip + "/branches/0", {headers: {"x-access-token": global.token}})
            .then(response => response.json())
            .then(responseJson => {
                setBranches(responseJson);
            });
        return "Loading";
    }

    if (devices === undefined) {
        fetch(global.localip + "/devices/0", {headers: {"x-access-token": global.token}})
            .then(response => response.json())
            .then(responseJson => {
                setDevices(responseJson);
            });
        return "Loading";
    }

    if (ratings === undefined) {
        fetch(global.localip + "/poursCompany/0", {headers: {"x-access-token": global.token}})
            .then(response => response.json())
            .then(responseJson => {
                setRatings(responseJson);
            });
        return "Loading";
    }

    if (customers === undefined) {
        fetch(global.localip + "/customers/0", {headers: {"x-access-token": global.token}})
            .then(response => response.json())
            .then(responseJson => {
                setCustomers(responseJson);
            });
        return "Loading";
    }

    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card plain>
                        <CardHeader plain color="primary">
                            <h4>
                                Dashboard
                            </h4>
                            <p>
                                A quick overview over all companies
                            </p>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={3}>
                                    <Card>
                                        <CardHeader color="warning" stats icon>
                                            <CardIcon color="warning">
                                                <Business></Business>
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Total companies</p>
                                            <h3 className={classes.cardTitle}>
                                                {companies.length}
                                            </h3>
                                        </CardHeader>
                                    </Card>
                                </GridItem>
                                <GridItem xs={12} sm={3}>
                                    <Card>
                                        <CardHeader color="primary" stats icon>
                                            <CardIcon color="primary">
                                                <LibraryBooks></LibraryBooks>
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Total branches</p>
                                            <h3 className={classes.cardTitle}>
                                                {branches.length}
                                            </h3>
                                        </CardHeader>
                                    </Card>
                                </GridItem>
                                <GridItem xs={12} sm={3}>
                                    <Card>
                                        <CardHeader color="info" stats icon>
                                            <CardIcon color="info">
                                                <People></People>
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Total Customers / Pours</p>
                                            <h3 className={classes.cardTitle}>
                                                {customers.length} / {ratings.length}
                                            </h3>
                                        </CardHeader>
                                    </Card>
                                </GridItem>
                                <GridItem xs={12} sm={3}>
                                    <Card>
                                        <CardHeader color="success" stats icon>
                                            <CardIcon color="success">
                                                <Icon>dock</Icon>
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Total devices</p>
                                            <h3 className={classes.cardTitle}>
                                                {devices.length}
                                            </h3>
                                        </CardHeader>
                                    </Card>
                                </GridItem>
                                {companies.map((company => {
                                    return <DashboardSuperadminCompanyRow companydata={company}></DashboardSuperadminCompanyRow>
                                }))}
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    )
}

export default DashboardSuperadmin
