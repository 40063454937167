import React from 'react'
import { Brush, CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import MaterialTable from 'material-table';
import { Chip, Grid } from '@material-ui/core';

function PoursDevice(props) {
    const pours = props.pours;

    let first_date = new Date();
    let last_date = new Date();

    for (let i = 0; i < pours.length; i++) {
        const element = pours[i];
        let date = new Date(element.created_at);
        if (date < first_date) {
            first_date = date;
        } else if (date > last_date) {
            last_date = date;
        }
    }

    let alldates = getDates(first_date, last_date);

    for (let i = 0; i < pours.length; i++) {
        const element = pours[i];
        let date = new Date(element.created_at);
        for (let j = 0; j < alldates.length; j++) {
            const element2 = alldates[j];
            if (date.getFullYear() === element2.date.getFullYear() && date.getMonth() === element2.date.getMonth() && date.getDate() === element2.date.getDate()) {
                element2.amount++;
            }
        }
    }


    const formatXAxis = (tickItem) => {
        return tickItem.toLocaleDateString();
    }


    return (<Grid container>
        <Grid item xs={12}>
            <ResponsiveContainer width="100%" aspect={3}>
                <LineChart
                    width={500}
                    height={300}
                    data={alldates}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" tickFormatter={formatXAxis} />
                    <YAxis />
                    <Tooltip labelFormatter={formatXAxis} />
                    <Legend />
                    <Brush dataKey="date" height={30} stroke="#8884d8" tickFormatter={formatXAxis} />
                    <Line type="monotone" dataKey="amount" stroke="#82ca9d" />
                </LineChart>
            </ResponsiveContainer>
        </Grid>
        <Grid item xs={12}>
            <StatisticsGraphics pours={pours}></StatisticsGraphics>
        </Grid>
    </Grid>
    )
}

function getDates(startDate, stopDate) {
    var dateArray = new Array();
    var currentDate = startDate;
    while (currentDate <= stopDate) {
        dateArray.push({ date: currentDate, amount: 0 })
        currentDate = new Date(currentDate);
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return dateArray;
}

function StatisticsGraphics(props) {
    var ratings = props.pours;
    var columns = [
        {
            title: "Rating ID",
            field: "id",
            defaultSort: "desc"
        }, {
            title: "Customer",
            field: "customer_id"
        }, {
            title: "Stars",
            field: "rating"
        }, {
            title: "Time",
            field: "updated",
            render: rowData => <>{new Date(rowData.updated).toLocaleDateString('de-DE', { year: 'numeric', month: 'numeric', day: 'numeric', hour: "2-digit", minute: "2-digit" })}</>
        }
    ]

    var rows = new Array;
    var ratingsbyrate = [0, 0, 0, 0, 0, 0]
    for (let i = 0; i < ratings.length; i++) {
        const element = ratings[i];
        ratingsbyrate[element.rating_stars] = ratingsbyrate[element.rating_stars] + 1
        rows[rows.length] = { id: element.id, customer_id: element.rating_user, updated: element.updated_at, rating: element.rating_stars }
    }

    return (
        <MaterialTable
            columns={columns}
            data={rows}
            title={<h3>Pour List <Chip label={"Total: " + ratings.length}></Chip></h3>}
            options={{
                search: false,
                paging: false,
                sorting: true
            }}
        />
    )
}


export default PoursDevice
