import { MenuItem, Select, TextField, Button, Grid, Snackbar } from '@material-ui/core';
import React from 'react'
import MuiAlert from '@material-ui/lab/Alert';
import axios from 'axios';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function AddThings(props) {
    const [value, setValue] = React.useState(0);
    const [name, setName] = React.useState("");
    const [permitted_company, setPermitted_company] = React.useState(0);
    const [permitted_branch, setPermitted_branch] = React.useState(0);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [snackbarMsg, setSnackbarMsg] = React.useState("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState("");

    const [wines, setWines] = React.useState(undefined);

    const companyData = props.companyData;
    const branchData = props.branchData;

    if (wines === undefined) {
        fetchWines();
    }

    function fetchWines(company) {
        if (company === undefined) {
            company = 0;
        }
        fetch(global.localip + "/wines/" + company, { headers: { "x-access-token": global.token } })
            .then(response => response.json())
            .then(responseJson => {
                setWines(responseJson);
            });
    }

    function resetInputs() {
        setName("");
        // setValue(0);
        // setPermitted_company(0);
        // setPermitted_branch(0);
        // setCompanyData(undefined);
        // setBranchData(undefined);
        setSnackbarSeverity("success");
        setSnackbarMsg("Added to system.");
        setOpenSnackbar(true);
        props.fetchCompanies();
        props.fetchBranches();
    }

    function addFunc() {
        if (name.trim() !== "") {
            if (value == 1) {
                if (permitted_branch !== 0) {
                    if (wines !== undefined && wines.length > 0) {
                        axios.post(global.localip + "/devices/", {
                            branche_id: permitted_branch, name: name
                        }, { headers: { "x-access-token": global.token } })
                            .then((response) => {
                                // console.log(response);
                                let defaultwine = wines[0];
                                let device_id = response.data.insertId;
                                let tapobj1 = { device_id: device_id, cooler_id: 0, side: 0, wine_id: defaultwine.id, pour_amount: 25, bottle_ml: defaultwine.bottle_content, locked: 0, residue: 15, cal_ml: 80, cal_time: 50 };
                                let tapobj2 = { device_id: device_id, cooler_id: 0, side: 1, wine_id: defaultwine.id, pour_amount: 25, bottle_ml: defaultwine.bottle_content, locked: 0, residue: 15, cal_ml: 80, cal_time: 50 };
                                let tapobj3 = { device_id: device_id, cooler_id: 0, side: 0, wine_id: defaultwine.id, pour_amount: 25, bottle_ml: defaultwine.bottle_content, locked: 0, residue: 15, cal_ml: 80, cal_time: 50 };
                                let tapobj4 = { device_id: device_id, cooler_id: 0, side: 1, wine_id: defaultwine.id, pour_amount: 25, bottle_ml: defaultwine.bottle_content, locked: 0, residue: 15, cal_ml: 80, cal_time: 50 };
                                let tapobj5 = { device_id: device_id, cooler_id: 0, side: 0, wine_id: defaultwine.id, pour_amount: 25, bottle_ml: defaultwine.bottle_content, locked: 0, residue: 15, cal_ml: 80, cal_time: 50 };
                                let tapobj6 = { device_id: device_id, cooler_id: 0, side: 1, wine_id: defaultwine.id, pour_amount: 25, bottle_ml: defaultwine.bottle_content, locked: 0, residue: 15, cal_ml: 80, cal_time: 50 };
                                let tapobj7 = { device_id: device_id, cooler_id: 0, side: 0, wine_id: defaultwine.id, pour_amount: 25, bottle_ml: defaultwine.bottle_content, locked: 0, residue: 15, cal_ml: 80, cal_time: 50 };
                                let tapobj8 = { device_id: device_id, cooler_id: 0, side: 1, wine_id: defaultwine.id, pour_amount: 25, bottle_ml: defaultwine.bottle_content, locked: 0, residue: 15, cal_ml: 80, cal_time: 50 };
                                let reqTap1 = axios.post(global.localip + "/" + 'taps/', tapobj1, { headers: { "x-access-token": global.token } });
                                let reqTap2 = axios.post(global.localip + "/" + 'taps/', tapobj2, { headers: { "x-access-token": global.token } });
                                let reqTap3 = axios.post(global.localip + "/" + 'taps/', tapobj3, { headers: { "x-access-token": global.token } });
                                let reqTap4 = axios.post(global.localip + "/" + 'taps/', tapobj4, { headers: { "x-access-token": global.token } });
                                let reqTap5 = axios.post(global.localip + "/" + 'taps/', tapobj5, { headers: { "x-access-token": global.token } });
                                let reqTap6 = axios.post(global.localip + "/" + 'taps/', tapobj6, { headers: { "x-access-token": global.token } });
                                let reqTap7 = axios.post(global.localip + "/" + 'taps/', tapobj7, { headers: { "x-access-token": global.token } });
                                let reqTap8 = axios.post(global.localip + "/" + 'taps/', tapobj8, { headers: { "x-access-token": global.token } });

                                reqTap1.then(response => {
                                    const tap1id = response.data.insertId;
                                    reqTap2.then(response => {
                                        const tap2id = response.data.insertId;
                                        reqTap3.then(response => {
                                            const tap3id = response.data.insertId;
                                            reqTap4.then(response => {
                                                const tap4id = response.data.insertId;
                                                reqTap5.then(response => {
                                                    const tap5id = response.data.insertId;
                                                    reqTap6.then(response => {
                                                        const tap6id = response.data.insertId;
                                                        reqTap7.then(response => {
                                                            const tap7id = response.data.insertId;
                                                            reqTap8.then(response => {
                                                                const tap8id = response.data.insertId;

                                                                let coolobj1 = { device_id: device_id, tapL_id: tap1id, tapR_id: tap2id, active: 1, can_id: 1, brightness: 100, temperature: defaultwine.serving_temperature };
                                                                let coolobj2 = { device_id: device_id, tapL_id: tap3id, tapR_id: tap4id, active: 1, can_id: 2, brightness: 100, temperature: defaultwine.serving_temperature };
                                                                let coolobj3 = { device_id: device_id, tapL_id: tap5id, tapR_id: tap6id, active: 1, can_id: 3, brightness: 100, temperature: defaultwine.serving_temperature };
                                                                let coolobj4 = { device_id: device_id, tapL_id: tap7id, tapR_id: tap8id, active: 1, can_id: 4, brightness: 100, temperature: defaultwine.serving_temperature };

                                                                let reqCool1 = axios.post(global.localip + "/" + 'cooler/', coolobj1, { headers: { "x-access-token": global.token } });
                                                                let reqCool2 = axios.post(global.localip + "/" + 'cooler/', coolobj2, { headers: { "x-access-token": global.token } });
                                                                let reqCool3 = axios.post(global.localip + "/" + 'cooler/', coolobj3, { headers: { "x-access-token": global.token } });
                                                                let reqCool4 = axios.post(global.localip + "/" + 'cooler/', coolobj4, { headers: { "x-access-token": global.token } });

                                                                axios.all([reqCool1, reqCool2, reqCool3, reqCool4])
                                                                    .then(axios.spread((...responses) => {
                                                                        const cool1id = responses[0].data.insertId;
                                                                        const cool2id = responses[1].data.insertId;
                                                                        const cool3id = responses[2].data.insertId;
                                                                        const cool4id = responses[3].data.insertId;

                                                                        let reqTapUpdate1 = axios.post(global.localip + "/" + 'taps/' + tap1id, { cooler_id: cool1id }, { headers: { "x-access-token": global.token } });
                                                                        let reqTapUpdate2 = axios.post(global.localip + "/" + 'taps/' + tap2id, { cooler_id: cool1id }, { headers: { "x-access-token": global.token } });
                                                                        let reqTapUpdate3 = axios.post(global.localip + "/" + 'taps/' + tap3id, { cooler_id: cool2id }, { headers: { "x-access-token": global.token } });
                                                                        let reqTapUpdate4 = axios.post(global.localip + "/" + 'taps/' + tap4id, { cooler_id: cool2id }, { headers: { "x-access-token": global.token } });
                                                                        let reqTapUpdate5 = axios.post(global.localip + "/" + 'taps/' + tap5id, { cooler_id: cool3id }, { headers: { "x-access-token": global.token } });
                                                                        let reqTapUpdate6 = axios.post(global.localip + "/" + 'taps/' + tap6id, { cooler_id: cool3id }, { headers: { "x-access-token": global.token } });
                                                                        let reqTapUpdate7 = axios.post(global.localip + "/" + 'taps/' + tap7id, { cooler_id: cool4id }, { headers: { "x-access-token": global.token } });
                                                                        let reqTapUpdate8 = axios.post(global.localip + "/" + 'taps/' + tap8id, { cooler_id: cool4id }, { headers: { "x-access-token": global.token } });

                                                                        axios.all([reqTapUpdate1, reqTapUpdate2, reqTapUpdate3, reqTapUpdate4, reqTapUpdate5, reqTapUpdate6, reqTapUpdate7, reqTapUpdate8])
                                                                            .then(axios.spread((...responses) => {
                                                                                resetInputs();

                                                                            })).catch(errors => {
                                                                                // errors.
                                                                            })
                                                                    })).catch(errors => {
                                                                        // errors.
                                                                    })
                                                            })
                                                        })
                                                    })
                                                })
                                            })
                                        })
                                    })
                                })

                                // axios.all([reqTap1, reqTap2, reqTap3, reqTap4, reqTap5, reqTap6, reqTap7, reqTap8])
                                //     .then(axios.spread((...responses) => {
                                //         console.log(responses);
                                //         const tap1id = responses[0].data.insertId;
                                //         const tap2id = responses[1].data.insertId;
                                //         const tap3id = responses[2].data.insertId;
                                //         const tap4id = responses[3].data.insertId;
                                //         const tap5id = responses[4].data.insertId;
                                //         const tap6id = responses[5].data.insertId;
                                //         const tap7id = responses[6].data.insertId;
                                //         const tap8id = responses[7].data.insertId;


                                //     })).catch(errors => {
                                //         // errors.
                                //     })
                            }, (error) => {
                                console.log(error);
                            });
                    } else {
                        alert("No wines in system.")
                    }
                }
            }
            if (value == 2) {
                if (permitted_company !== 0) {
                    axios.post(global.localip + "/branches/", {
                        company_id: permitted_company, name: name
                    }, { headers: { "x-access-token": global.token } })
                        .then((response) => {
                            resetInputs();
                        }, (error) => {
                            console.log(error);
                        });
                }
            }
            if (value == 3) {
                axios.post(global.localip + "/companies/", {
                    name: name
                }, { headers: { "x-access-token": global.token } })
                    .then((response) => {
                        resetInputs();
                    }, (error) => {
                        console.log(error);
                    });
            }
        }
    }

    function handleSnackbarClose() {
        setOpenSnackbar(false);
        setSnackbarMsg("");
    }
    return (
        <div>
            <Snackbar open={openSnackbar} autoHideDuration={10000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} >
                    {snackbarMsg}
                </Alert>
            </Snackbar>
            <Grid container spacing={3}>
                <Grid container justify="center" spacing={1} item xs={12}>
                    <h4>What do you want to add?</h4>
                    <Grid container item justify="center" xs={12}>
                        <Select variant='outlined' value={value} onChange={(e) => setValue(e.target.value)}>
                            <MenuItem value={0}><h5>Please select</h5></MenuItem>
                            <MenuItem value={3}><h5>Company</h5></MenuItem>
                            <MenuItem value={2}><h5>Branch</h5></MenuItem>
                            <MenuItem value={1}><h5>Device</h5></MenuItem>
                        </Select>
                    </Grid>
                    <Grid container justify="center" item xs={12}>
                        {(value <= 2 && value != 0) ? <Select variant='outlined' label="Company" value={permitted_company} onChange={(e) => {
                            setPermitted_company(e.target.value)
                            setPermitted_branch(0);
                            fetchWines(e.target.value);
                        }}>
                            <MenuItem value={0}>Select Company</MenuItem>
                            {companyData.map(company => {
                                return (
                                    <MenuItem value={company.id}>{company.name} ({company.id})</MenuItem>
                                );
                            })}
                        </Select> : null}
                    </Grid>
                    <Grid container justify="center" item xs={12}>
                        {value == 1 ? <Select variant='outlined' label="Branch" disabled={permitted_company === 0} value={permitted_branch} onChange={(e) => setPermitted_branch(e.target.value)}>
                            <MenuItem value={0}>Select Branch</MenuItem>
                            {branchData.map(branch => {
                                if (branch.company_id === permitted_company) {
                                    return (
                                        <MenuItem value={branch.id}>{branch.name} ({branch.id})</MenuItem>
                                    );
                                }
                            })}
                        </Select> : null}
                    </Grid>
                    <Grid container justify="center" item xs={6}>

                    </Grid>
                    <Grid container justify="center" item xs={12}>
                        {value >= 1 ? <TextField variant="outlined" label="Name" value={name} onChange={(e) => setName(e.target.value)}></TextField> : null}
                    </Grid>
                    <Grid container justify="center" item xs={12}>
                        {value >= 1 ? <Button color="primary" onClick={addFunc} variant="outlined">Add</Button> : null}

                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default AddThings
