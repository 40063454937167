/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";

// core components
import styles from "assets/jss/material-dashboard-react/components/footerStyle.js";
import {Link, Route} from 'react-router-dom';

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            {props.routes.map((prop, key) => {
              return <ListItem className={classes.inlineBlock} key={key}>
                <Link to={prop.layout + prop.path}>{prop.name}</Link>
              </ListItem>
            })}
          </List>
        </div>
        <p className={classes.right}>
          <span>
            Copyright © {new Date().getFullYear()} By the Glass®. All Rights Reserved.
          </span>
        </p>
      </div>
    </footer>
  );
}
