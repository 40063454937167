import React from 'react'
import { MenuItem, Select, Grid, TextField, Button } from '@material-ui/core';
var axios = require("axios");

function AddUserAccount(props) {
    const rank = props.rank;
    const company_id = props.company_id;
    const branch_id = props.branch_id;
    const [companyData, setCompanyData] = React.useState(undefined);
    const [branchData, setBranchData] = React.useState(undefined);

    const [email, setEmail] = React.useState(props.edit ? props.email : "");
    const [password, setPassword] = React.useState("");
    const [password2, setPassword2] = React.useState("");
    const [emailerror, setEmailError] = React.useState(false);
    const [passwordError, setPasswordError] = React.useState(false);
    const [permitted_company, setPermitted_company] = React.useState(props.edit ? props.permitted_company : "");
    const [permitted_branch, setPermitted_branch] = React.useState(props.edit ? props.permitted_branch : "");
    const [ranknew, setRankNew] = React.useState(props.edit ? props.ranknew : "");

    //Nur eigene Company
    if (rank !== 3) {
        if (permitted_company !== company_id) {
            setPermitted_company(company_id);
        }
    }
    //Nur eigene Branch
    if (rank < 2) {
        if (permitted_branch !== branch_id) {
            setPermitted_branch(branch_id);
        }
    }


    if (ranknew == 3) {
        if (permitted_company != 0) {
            setPermitted_company(0);
        }
        if (permitted_branch != 0) {
            setPermitted_branch(0);
        }
    }


    if (companyData === undefined) {
        if (rank === 3) {
            fetch(global.localip + "/companies/", { headers: { "x-access-token": global.token } })
                .then(response => response.json())
                .then(responseJson => {
                    setCompanyData(responseJson);
                });
        } else {
            fetch(global.localip + "/companies/", { headers: { "x-access-token": global.token } })
                .then(response => response.json())
                .then(responseJson => {
                    for (let i = 0; i < responseJson.length; i++) {
                        const element = responseJson[i];
                        if (element.id == company_id) {
                            setCompanyData(responseJson);
                        }
                    }
                });
        }
        return "Loading";
    }

    if (branchData === undefined) {
        if (rank === 3) {
            fetch(global.localip + "/branches/0", { headers: { "x-access-token": global.token } })
                .then(response => response.json())
                .then(responseJson => {
                    setBranchData(responseJson);
                });
        } else {
            fetch(global.localip + "/branches/" + company_id, { headers: { "x-access-token": global.token } })
                .then(response => response.json())
                .then(responseJson => {
                    setBranchData(responseJson);
                });
        }
        return "Loading";
    }

    function addUser() {
        const mailScheme = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (mailScheme.test(email)) {
            setEmailError(false);
            if (password !== password2 || password.length < 4 && !props.edit) {
                setPasswordError(true);
            } else {
                if (props.edit) {
                    if (password.length > 0 && (password !== password2 || password.length < 4)) {
                        setPasswordError(true);
                        return;
                    }
                    // console.log(props);
                    axios.post(global.localip + "/login/" + props.editid, {
                        email: email, password: password.length > 0 ? password : undefined, rank: ranknew, company_id: permitted_company, branche_id: permitted_branch
                    }, { headers: { "x-access-token": global.token } })
                        .then((response) => {
                            if (props.doneFunc != undefined) {
                                props.doneFunc();
                            }
                        }, (error) => {

                        });
                } else {
                    axios.post(global.localip + "/register/", {
                        email: email, password: password, first_name: "", last_name: "", rank: ranknew, company_id: permitted_company, branche_id: permitted_branch
                    }, { headers: { "x-access-token": global.token } })
                        .then((response) => {
                            setRankNew(0);
                            setPermitted_branch(0);
                            setPermitted_company(0);
                            setEmail("");
                            setPassword("");
                            setPassword2("");
                            if (props.doneFunc != undefined) {
                                props.doneFunc();
                            }
                        }, (error) => {

                        });

                }
            }
        } else {
            setEmailError(true);
        }
    }

    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <Select label="Company" disabled={rank !== 3} value={permitted_company} onChange={(e) => {
                        setPermitted_company(e.target.value)
                        setPermitted_branch(0);
                    }}>
                        <MenuItem value={0}>All Companies</MenuItem>
                        {companyData.map(company => {
                            return (
                                <MenuItem value={company.id}>{company.name}</MenuItem>
                            );
                        })}
                    </Select>
                </Grid>
                <Grid item xs={4}>
                    <Select label="Branch" disabled={rank < 2 || permitted_company === 0} value={permitted_branch} onChange={(e) => setPermitted_branch(e.target.value)}>
                        <MenuItem value={0}>All Branches</MenuItem>
                        {branchData.map(branch => {
                            if (branch.company_id === permitted_company) {
                                return (
                                    <MenuItem value={branch.id}>{branch.name}</MenuItem>
                                );
                            }
                        })}
                    </Select>
                </Grid>
                <Grid item xs={4}>
                    <Select title="Rank" value={ranknew} onChange={(e) => {
                        setRankNew(e.target.value)
                    }}>
                        <MenuItem value={0}>Executive</MenuItem>
                        {rank >= 1 ? <MenuItem value={1}>Manager</MenuItem> : null}
                        {rank >= 2 ? <MenuItem value={2}>Administrator</MenuItem> : null}
                        {rank === 3 ? <MenuItem value={3}>Super-Admin</MenuItem> : null}
                    </Select>
                </Grid>
                <Grid item xs={4}>
                    <TextField variant="outlined" error={emailerror} label="E-Mail" value={email} onChange={(e) => { setEmail(e.target.value) }}></TextField>
                </Grid>
                <Grid item xs={4}>
                    <TextField variant="outlined" error={passwordError} label="Password" value={password} onChange={(e) => { setPassword(e.target.value) }}></TextField>
                </Grid>
                <Grid item xs={4}>
                    <TextField variant="outlined" error={passwordError} label="Repeat password" value={password2} onChange={(e) => { setPassword2(e.target.value) }}></TextField>
                </Grid>
                <br></br>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" fullWidth onClick={addUser}>{props.edit ? "Edit" : "Add"} User</Button>
                </Grid>
            </Grid>
        </div>
    )
}

export default AddUserAccount
