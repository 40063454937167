import { Dialog, DialogContent, DialogTitle, Button, Box, Typography } from '@material-ui/core';
import { Tab } from '@material-ui/icons';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React from 'react'
import { Tabs } from 'react-bootstrap';
import AddThings from './AddThings';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


function SystemManager(props) {
    const [openModal, setOpenModal] = React.useState(false);
    const [companyData, setCompanyData] = React.useState(undefined);
    const [branchData, setBranchData] = React.useState(undefined);
    const [deviceData, setDeviceData] = React.useState(undefined);

    const handleModalOpen = () => {
        setOpenModal(true);
    };

    const handleModalClose = () => {
        setOpenModal(false);
    };

    function fetchCompanies() {
        fetch(global.localip + "/companies/", { headers: { "x-access-token": global.token } })
            .then(response => response.json())
            .then(responseJson => {
                setCompanyData(responseJson);
            });
    }

    function fetchBranches() {
        fetch(global.localip + "/branches/0", { headers: { "x-access-token": global.token } })
            .then(response => response.json())
            .then(responseJson => {
                setBranchData(responseJson);
            });
    }

    function fetchDevices() {
        fetch(global.localip + "/devices/0", { headers: { "x-access-token": global.token } })
            .then(response => response.json())
            .then(responseJson => {
                setDeviceData(responseJson);
            });
    }


    if (companyData === undefined) {
        fetchCompanies();
        return "Loading";
    }
    if (branchData === undefined) {
        fetchBranches();
        return "Loading";
    }
    if (deviceData === undefined) {
        fetchDevices();


        return "Loading";
    }

    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card plain>
                        <CardHeader plain color="primary">
                            <h4>
                                Add To System
                            </h4>
                            <p>
                                Add companies, branches and devices to the system
                            </p>
                        </CardHeader>
                        <CardBody>
                            <AddThings fetchCompanies={fetchCompanies} fetchBranches={fetchBranches} companyData={companyData} branchData={branchData} deviceData={deviceData}></AddThings>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            {/* <Button variant="outlined" onClick={handleModalOpen}>
                Add to System
            </Button>
            <Dialog open={openModal} onClose={handleModalClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add to System</DialogTitle>
                <DialogContent>
                    <AddThings companyData={companyData} branchData={branchData} deviceData={deviceData}></AddThings>
                </DialogContent>
            </Dialog> */}
        </div>
    )
}

export default SystemManager
