import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import MaterialTable from "material-table";
import { Alert } from 'reactstrap';
import { Button, Modal, Grid, Paper, TextField, Backdrop, CircularProgress, IconButton, Typography, Slider, Snackbar } from '@material-ui/core';
import { Close, Edit } from "@material-ui/icons";
import { v4 as uuidv4 } from 'uuid';
import MuiAlert from '@material-ui/lab/Alert';


var axios = require("axios");

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

function AlertM(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function EditModal(props) {
  var data = props.data;

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [errorsku, seterrorsku] = React.useState(false);
  const [erroranzeigename2, seterroranzeigename2] = React.useState(false);
  const [errorean, seterrorean] = React.useState(false);
  const [errormanufacturer, seterrormanufacturer] = React.useState(false);
  const [errorcharacterisitcs, seterrorcharacterisitcs] = React.useState(false);
  const [errorrecommendations, seterrorrecommendations] = React.useState(false);
  const [errorprice, seterrorprice] = React.useState(false);
  const [errorheadline, seterrorheadline] = React.useState(false);
  const [errororiginc, seterrororiginc] = React.useState(false);
  const [errororigina, seterrororigina] = React.useState(false);
  const [errortemp, seterrortemp] = React.useState(false);
  const [errorsupplier, seterrorsupplier] = React.useState(false);
  const [errorbottlecontent, seterrorbottlecontent] = React.useState(false);
  const [eanvalue, setEanvalue] = React.useState(data.ean);
  const [bottlecontent, setBottlecontent] = React.useState(data.bottle_content);
  const [articlevalue, setarticlevalue] = React.useState(data.article_code);
  const [pricevalue, setPricevalue] = React.useState(data.price);
  const [tempvalue, setTempvalue] = React.useState(data.serving_temperature);
  const [buttonText, setButtonText] = React.useState("Edit Wine");

  React.useEffect(() => {
    setEanvalue(data.ean);
    setBottlecontent(data.bottle_content);
    setarticlevalue(data.article_code);
    setPricevalue(data.price);
    setTempvalue(data.serving_temperature);
  }, [open])

  function buttonClicked() {
    {
      if (loading) {
        return;
      }
      var newData = { ...data };
      newData.ean = eanvalue;
      newData.bottle_content = bottlecontent;
      newData.sku = document.getElementById("sku").value;
      newData.anzeigename2 = document.getElementById("anzeigename2").value;
      newData.manufacturer = document.getElementById("manufacturer").value;
      newData.supplier = document.getElementById("supplier").value;
      newData.characteristics = document.getElementById("characteristics").value;
      newData.consumption_recommendations = document.getElementById("recommendations").value;
      newData.price = document.getElementById("price").value.replaceAll(",", ".");
      newData.origin_country = document.getElementById("origin_country").value;
      newData.origin_region = document.getElementById("origin_region").value;
      newData.headline = document.getElementById("headline").value;
      //newData.type = document.getElementById("type").value;
      newData.quality = document.getElementById("quality").value;
      newData.grape_variety = document.getElementById("grape_variety").value;
      newData.color = document.getElementById("color").value;
      newData.taste = document.getElementById("taste").value;
      newData.alcohol = document.getElementById("alcohol").value;
      newData.sugar = document.getElementById("sugar").value;
      newData.acidity = document.getElementById("acidity").value;
      //newData.storage_life = document.getElementById("storage_life").value;
      newData.allergens = document.getElementById("allergens").value;
      newData.special_price = document.getElementById("special_price").value;
      newData.vintage = document.getElementById("vintage").value;
      //newData.soil = document.getElementById("soil").value;
      //newData.wine_categories = document.getElementById("wine_categories").value;
      //newData.season = document.getElementById("season").value;
      //newData.wine_food_categories = document.getElementById("wine_food_categories").value;
      //newData.awards = document.getElementById("awards").value;

      newData.tempvalue = tempvalue;
      newData.article_code = articlevalue;
      console.log(articlevalue);
      newData.ean = newData.ean.toString().replaceAll(",", ".");
      newData.ean = newData.ean.toString().replaceAll(".", "");
      newData.tempvalue = newData.tempvalue.toString().replaceAll(",", ".");
      newData.sku = newData.sku.replaceAll(",", ".");
      newData.anzeigename2 = newData.anzeigename2.replaceAll(",", ".");
      newData.manufacturer = newData.manufacturer.replaceAll(",", ".");
      newData.supplier = newData.supplier.replaceAll(",", ".");
      //newData.characteristics = newData.characteristics.replaceAll(",", ".");
      //newData.consumption_recommendations = newData.consumption_recommendations.replaceAll(",", ".");
      newData.price = newData.price.replaceAll(",", ".");
      newData.origin_country = newData.origin_country.replaceAll(",", ".");
      newData.origin_region = newData.origin_region.replaceAll(",", ".");
      newData.article_code = newData.article_code.toString().replaceAll(",", ".");
      newData.article_code = newData.article_code.toString().replaceAll(".", "");

      // if (Object.values(newData).indexOf("'") > -1) {
      //   document.getElementById("alertwines").style.display = "block";
      //   document.getElementById("alertwines").innerHTML = "use \' instead '";
      //   setLoading(false);
      //   setButtonText("Edit Wine");
      //   error = true;
      //   setTimeout(() => {
      //     try {
      //       document.getElementById("alertwines").style.display = "none";
      //     } catch (error) {
      //     }
      //   }, 10000);
      //   return;
      // }

      fetch(global.localip + "/wines/" + company_id, { headers: { "x-access-token": global.token } })
        .then(response => response.json())
        .then(responseJson => {
          let eantaken = false;
          for (let i = 0; i < responseJson.length; i++) {
            const element = responseJson[i];
            if (element.id !== newData.id && element.ean == newData.ean) {
              eantaken = true;
            }
          }
          if (eantaken) {
            document.getElementById("alertwines").style.display = "block";
            document.getElementById("alertwines").innerHTML = "Ean is already in use.";
            setLoading(false);
            setButtonText("Edit Wine");
            error = true;
            setTimeout(() => {
              try {
                document.getElementById("alertwines").style.display = "none";
              } catch (error) {
              }
            }, 10000);
            return;
          }
          newData.image = newData.ean + ".png"
          var file = document.getElementById("wineimgupload").files[0];
          if (file != undefined) {
            if (file.size >= 2000000) {
              document.getElementById("alertwines").style.display = "block";
              document.getElementById("alertwines").innerHTML = "Uploaded File is to large. Max is 2 Mb.";
              setLoading(false);
              setButtonText("Edit Wine");
              error = true;
              setTimeout(() => {
                try {
                  document.getElementById("alertwines").style.display = "none";
                } catch (error) {
                }
              }, 10000);
              return;
            } else {
              const reader = new FileReader();

              reader.readAsDataURL(file)
              reader.addEventListener("load", function () {
                props.addImage(newData.ean, file, true)
              }, false);
            }
          } else {
            if (props.newWine) {
              document.getElementById("alertwines").style.display = "block";
              document.getElementById("alertwines").innerHTML = "Please upload a file";
              setLoading(false);
              setButtonText("Edit Wine");
              error = true;
              setTimeout(() => {
                try {
                  document.getElementById("alertwines").style.display = "none";
                } catch (error) {
                }
              }, 10000);
              return;
            }
          }

          newData.price = parseFloat(newData.price);
          newData.ean = parseInt(newData.ean);
          var error = false;
          if (newData.ean == undefined || newData.sku == undefined) {
            document.getElementById("alertwines").style.display = "block";
            document.getElementById("alertwines").innerHTML = "Please set ean and sku";
            setTimeout(() => {
              seterrorean(false);
              seterrorsku(false);
              try {
                document.getElementById("alertwines").style.display = "none";
              } catch (error) {
              }
            }, 10000);
            setLoading(false);
            setButtonText("Edit Wine");
            seterrorean(true);
            seterrorsku(true);
            error = true;
            return;
          }
          console.log("ean", newData.ean);
          console.log("eanlength", newData.ean.toString().length);
          if (newData.ean.toString().length != 13) {
            document.getElementById("alertwines").style.display = "block";
            document.getElementById("alertwines").innerHTML = "Ean must be 13 digits long";
            setTimeout(() => {
              seterrorean(false);
              try {
                document.getElementById("alertwines").style.display = "none";
              } catch (error) {
              }
            }, 10000);
            setLoading(false);
            setButtonText("Edit Wine");
            seterrorean(true);
            error = true;
            return;
          }
          /*if (newData.sku == undefined) {
            document.getElementById("alertwines").style.display = "block";
            document.getElementById("alertwines").innerHTML = "Please set Displayname2 first";
            setTimeout(() => {
              seterroranzeigename2(false);
              try {
                document.getElementById("alertwines").style.display = "none";
              } catch (error) {
              }
            }, 10000);
            setLoading(false);
            setButtonText("Edit Wine");
            seterroranzeigename2(true);
            error = true;
            return;
          }
*/
          if (newData.ean.toString().length != 13) {
            document.getElementById("alertwines").style.display = "block";
            document.getElementById("alertwines").innerHTML = "The EAN you entered was not 13 characters long.";
            setTimeout(() => {
              try {
                document.getElementById("alertwines").style.display = "none";
                seterrorean(false);
              } catch (error) {

              }
            }, 10000);
            setLoading(false);
            setButtonText("Edit Wine");
            seterrorean(true);
            error = true;
            return;
          }
          if (newData.sku.trim().length == 0) {
            document.getElementById("alertwines").style.display = "block";
            document.getElementById("alertwines").innerHTML = "Please enter a Displayname 1 for the wine.";
            setTimeout(() => {
              try {
                document.getElementById("alertwines").style.display = "none";
              } catch (error) {
                seterrorsku(false);
              }
            }, 10000);
            setLoading(false);
            setButtonText("Edit Wine");
            seterrorsku(true);

            error = true;
            return;
          }
          if (newData.bottle_content.toString().trim().length == 0) {
            document.getElementById("alertwines").style.display = "block";
            document.getElementById("alertwines").innerHTML = "Please enter bottle content.";
            setTimeout(() => {
              try {
                document.getElementById("alertwines").style.display = "none";
              } catch (error) {
                seterrorbottlecontent(false);
              }
            }, 10000);
            setLoading(false);
            setButtonText("Edit Wine");
            seterrorbottlecontent(true);

            error = true;
            return;
          }
          // if (newData.anzeigename2.trim().length == 0) {
          //   document.getElementById("alertwines").style.display = "block";
          //   document.getElementById("alertwines").innerHTML = "Please enter a Displayname2 for the wine.";
          //   setTimeout(() => {
          //     try {
          //       document.getElementById("alertwines").style.display = "none";
          //     } catch (error) {
          //       seterroranzeigename2(false);
          //     }
          //   }, 10000);
          //   setLoading(false);
          //   setButtonText("Edit Wine");
          //   seterroranzeigename2(true);

          //   error = true;
          //   return;
          // }


          if (newData.characteristics.trim().length == 0) {
            document.getElementById("alertwines").style.display = "block";
            document.getElementById("alertwines").innerHTML = "Please enter characteristics for the wine.";
            setTimeout(() => {
              try {
                document.getElementById("alertwines").style.display = "none";
              } catch (error) {
                seterroranzeigename2(false);
              }
            }, 10000);
            setLoading(false);
            setButtonText("Edit Wine");
            seterroranzeigename2(true);

            error = true;

            setTimeout(() => {
              seterrorcharacterisitcs(false);
            }, 10000);
            seterrorcharacterisitcs(true);
            setLoading(false);
            error = true;
            return;
          }
          if (newData.consumption_recommendations.trim().length == 0) {
            document.getElementById("alertwines").style.display = "block";
            document.getElementById("alertwines").innerHTML = "Please enter consumption recommendations for the wine.";
            setTimeout(() => {
              try {
                document.getElementById("alertwines").style.display = "none";
              } catch (error) {
                seterroranzeigename2(false);
              }
            }, 10000);
            setLoading(false);
            setButtonText("Edit Wine");
            seterroranzeigename2(true);

            error = true;


            setTimeout(() => {
              seterrorrecommendations(false);
            }, 10000);
            seterrorrecommendations(true);
            setLoading(false);
            error = true;
            return;
          }


          if (isNaN(newData.price) || newData.price.toString().trim().length == 0) {
            document.getElementById("alertwines").style.display = "block";
            document.getElementById("alertwines").innerHTML = "Please enter price for the wine.";
            setTimeout(() => {
              try {
                document.getElementById("alertwines").style.display = "none";
              } catch (error) {
                seterroranzeigename2(false);
              }
            }, 10000);
            setLoading(false);
            setButtonText("Edit Wine");
            seterroranzeigename2(true);

            error = true;
            setTimeout(() => {
              seterrorprice(false);
            }, 10000);
            seterrorprice(true);
            setLoading(false);
            error = true;
            return;
          }
          if (isNaN(newData.tempvalue) || newData.tempvalue.toString().trim().length == 0) {
            document.getElementById("alertwines").style.display = "block";
            document.getElementById("alertwines").innerHTML = "Please enter serving temperature for the wine.";
            setTimeout(() => {
              try {
                document.getElementById("alertwines").style.display = "none";
              } catch (error) {
                seterroranzeigename2(false);
              }
            }, 10000);
            setLoading(false);
            setButtonText("Edit Wine");
            seterroranzeigename2(true);

            error = true;
            setTimeout(() => {
              seterrortemp(false);
            }, 10000);
            seterrortemp(true);
            setLoading(false);
            error = true;
            return;
          }


          if (error) {
            return;
          }


          setLoading(true);
          setButtonText("Loading...");

          newData.id = data.id;

          // newData.article_code = data.article_code;

          newData.serving_temperature = newData.tempvalue;

          if (props.newWine) {
            props.addWine(newData);
            props.setSnackbar(true, "Wine added succesfully", "success");
            setTimeout(() => {
              var arr = [...props.winedata];
              arr.push(newData);
              for (let i = 0; i < arr.length; i++) {
                let element = arr[i];
                element.company_id = company_id;
                element.image = global.getImage(element);
              }
              setOpen(false);
              setLoading(false);
              setButtonText("Edit Wine");
              props.parent.setState({
                data: arr
              });
              props.closeModal();

            }, 1000);
          } else {
            props.updateWine(newData, data);

            props.setSnackbar(true, "Wine updated succesfully", "success");
            setTimeout(() => {
              var arr = [...props.winedata];

              for (let i = 0; i < arr.length; i++) {
                let element = arr[i];
                if (element.id == newData.id) {
                  arr[i] = newData;
                }
                arr[i].image = global.getImage(arr[i]);
              }
              setOpen(false);
              setLoading(false);
              setButtonText("Edit Wine");
              console.log("arr", arr);
              props.parent.setState({
                data: arr
              });

            }, 1000);
          }

        });
    }
  }

  function fixStr(str, int) {
    str = str.replaceAll(",", ".");
    str = str.replaceAll(/[^\d.-]/g, "");
    if (int) {
      str = str.replaceAll(".", "");
    }
    return str;
  }

  if (props.newWine) {
    return <>
      <Grid container>
        <Grid item xs={12}>
          <Paper style={{ padding: "20px" }}>
            <IconButton style={{ position: "absolute", top: "10px", right: "10px" }}>
              <Close onClick={() => {
                setOpen(false);
                setEanvalue("");
                setPricevalue("");
                setTempvalue("");
                props.closeModal();
              }}></Close>
            </IconButton>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h2>Add a new Wine</h2>
                <h4>Here you can add new Wines. Make sure all required fields* are filled</h4>
              </Grid>
              <Grid item xs={12}>
                {global.getImage(undefined)}
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Headline" id="headline" error={errorheadline} defaultValue={data.headline}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Displayname1*" inputProps={{ maxLength: 30 }} id="sku" error={errorsku} defaultValue={data.sku}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Displayname2" inputProps={{ maxLength: 30 }} id="anzeigename2" error={erroranzeigename2} defaultValue={data.anzeigename2}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="EAN*" value={eanvalue} id="ean" error={errorean} onChange={(e) => {
                  var val = e.target.value.replaceAll(",", ".");
                  if (!isNaN(val)) {
                    setEanvalue(val);
                  }
                }}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Article code" value={articlevalue} id="ean" onChange={(e) => {
                  var val = e.target.value.replaceAll(",", ".");
                  if (!isNaN(val)) {
                    setarticlevalue(val);
                  }
                }}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Bottle content (ml)*" value={bottlecontent} id="ean" error={errorbottlecontent} onChange={(e) => {
                  var val = e.target.value.replaceAll(",", ".");
                  if (!isNaN(val)) {
                    if (!val.includes(".")) {
                      setBottlecontent(val);
                    }
                  }
                }}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Manufacturer" id="manufacturer" error={errormanufacturer} defaultValue={data.manufacturer}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Supplier" id="supplier" error={errorsupplier} defaultValue={data.supplier}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth multiline label="Characteristics*" inputProps={{ maxLength: 400 }} id="characteristics" error={errorcharacterisitcs} defaultValue={data.characteristics}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Recommendations*" inputProps={{ maxLength: 110 }} id="recommendations" error={errorrecommendations} defaultValue={data.consumption_recommendations}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Price*" id="price" value={pricevalue} error={errorprice} onChange={(e) => {
                  var val = e.target.value.replaceAll(",", ".");
                  if (!isNaN(val)) {
                    setPricevalue(val);
                  }
                }}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Special Price" id="special_price" defaultValue={data.special_price} onChange={(e) => {
                  e.target.value = fixStr(e.target.value);
                }}></TextField>
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom>
                  Serving Temperature (°C)*: {tempvalue}
                </Typography>
                <Slider min={6} max={21} value={tempvalue} onChange={(e, value) => {
                  setTempvalue(value);
                }}>
                </Slider>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Origin Country" id="origin_country" error={errororiginc} defaultValue={data.origin_country}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Origin Region" id="origin_region" defaultValue={data.origin_region}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Quality" id="quality" defaultValue={data.quality}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Grape Variety" id="grape_variety" defaultValue={data.grape_variety}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Color" id="color" defaultValue={data.color}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Taste" id="taste" defaultValue={data.taste}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Alcohol (%)" id="alcohol" defaultValue={data.alcohol} onChange={(e) => {
                  e.target.value = fixStr(e.target.value);
                }}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Sugar (g/L)" id="sugar" defaultValue={data.sugar} onChange={(e) => {
                  e.target.value = fixStr(e.target.value);
                }}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Acidity (g/L)" id="acidity" defaultValue={data.acidity} onChange={(e) => {
                  e.target.value = fixStr(e.target.value);
                }}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Allergens" id="allergens" defaultValue={data.allergens}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Vintage" id="vintage" defaultValue={data.vintage} onChange={(e) => {
                  e.target.value = fixStr(e.target.value, true);
                }}></TextField>
              </Grid>
              <Alert color="warning" id="alertwines" style={{ display: "none" }}>
                Placeholder
              </Alert>
              <Button
                variant="outlined"
                color="primary"
                onClick={buttonClicked}>{buttonText}</Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>;
  }
  return <>
    <IconButton onClick={() => setOpen(true)}>
      <Edit></Edit>
    </IconButton>
    <Modal
      style={{ margin: "20px", overflow: "scroll" }}
      open={open}
      onClose={() => {
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Paper style={{ padding: "20px" }}>
            <IconButton style={{ position: "absolute", top: "10px", right: "10px" }}>
              <Close onClick={() => {
                setOpen(false);
                setEanvalue("");
                setPricevalue("");
                setTempvalue("");
              }}></Close>
            </IconButton>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h2>Edit a Wine</h2>
                <h4>Here you can edit Wines. Make sure all required fields* are filled out</h4>
              </Grid>
              <Grid item xs={12}>
                {global.getImage(undefined)}
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Headline" id="headline" error={errorheadline} defaultValue={data.headline}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Displayname1*" id="sku" error={errorsku} defaultValue={data.sku}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Displayname2" id="anzeigename2" error={erroranzeigename2} defaultValue={data.anzeigename2}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="EAN*" value={eanvalue} id="ean" error={errorean} onChange={(e) => {
                  var val = e.target.value.replaceAll(",", ".");
                  if (!isNaN(val)) {
                    setEanvalue(val);
                  }
                }}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Article code" value={articlevalue} id="article_code" onChange={(e) => {
                  var val = e.target.value.replaceAll(",", ".");
                  if (!isNaN(val)) {
                    console.log(val);
                    setarticlevalue(val);
                  }
                }}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Bottle content (ml)*" value={bottlecontent} id="ean" error={errorbottlecontent} onChange={(e) => {
                  var val = e.target.value.replaceAll(",", ".");
                  if (!isNaN(val)) {
                    if (!val.includes(".")) {
                      setBottlecontent(val);
                    }
                  }
                }}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Manufacturer" id="manufacturer" error={errormanufacturer} defaultValue={data.manufacturer}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Supplier" id="supplier" error={errorsupplier} defaultValue={data.supplier}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth multiline label="Characteristics*" inputProps={{ maxLength: 400 }} id="characteristics" error={errorcharacterisitcs} defaultValue={data.characteristics}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Recommendations*" inputProps={{ maxLength: 110 }} id="recommendations" error={errorrecommendations} defaultValue={data.consumption_recommendations}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Price*" id="price" value={pricevalue} error={errorprice} onChange={(e) => {
                  var val = e.target.value.replaceAll(",", ".");
                  if (!isNaN(val)) {
                    setPricevalue(val);
                  }
                }}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Special Price" id="special_price" defaultValue={data.special_price} onChange={(e) => {
                  e.target.value = fixStr(e.target.value);
                }}></TextField>
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom>
                  Serving Temperature (°C)*: {tempvalue}
                </Typography>
                <Slider min={6} max={21} value={tempvalue} onChange={(e, value) => {
                  setTempvalue(value);
                }}>
                </Slider>
                {/* <TextField variant="outlined" fullWidth label="Serving Temperature (°C)*" id="serving_temperature" value={tempvalue} error={errortemp} onChange={(e) => {
                  var val = e.target.value.replaceAll(",", ".");
                  if (!isNaN(val)) {
                    setTempvalue(val);
                  }
                }}></TextField> */}
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Origin Country" id="origin_country" error={errororiginc} defaultValue={data.origin_country}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Origin Region" id="origin_region" defaultValue={data.origin_region}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Quality" id="quality" defaultValue={data.quality}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Grape Variety" id="grape_variety" defaultValue={data.grape_variety}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Color" id="color" defaultValue={data.color}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Taste" id="taste" defaultValue={data.taste}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Alcohol (%)" id="alcohol" defaultValue={data.alcohol} onChange={(e) => {
                  e.target.value = fixStr(e.target.value);
                }}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Sugar (g/L)" id="sugar" defaultValue={data.sugar} onChange={(e) => {
                  e.target.value = fixStr(e.target.value);
                }}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Acidity (g/L)" id="acidity" defaultValue={data.acidity} onChange={(e) => {
                  e.target.value = fixStr(e.target.value);
                }}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Allergens" id="allergens" defaultValue={data.allergens}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth label="Vintage" id="vintage" defaultValue={data.vintage} onChange={(e) => {
                  e.target.value = fixStr(e.target.value, true);
                }}></TextField>
              </Grid>
              <Alert color="warning" id="alertwines" style={{ display: "none" }}>
                Placeholder
              </Alert>
              <Button
                variant="outlined"
                color="primary"
                onClick={buttonClicked}>{buttonText}</Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Modal>
  </>;
}



const useStyles = makeStyles(styles);

let company_id;

export default function TableListWrapper(props) {

  let images = props.images;
  let winedata2 = props.winedata;
  let winedata = [];
  for (let i = 0; i < winedata2.length; i++) {
    const element = winedata2[i];
    if (element.company_id == props.company_id) {
      winedata.push(element);
    }
  }
  let setWineData = props.setWines;
  let setImages = props.setImages;

  company_id = props.company_id;

  if (winedata == undefined) {
    fetch(global.localip + "/wines/" + props.company_id, { headers: { "x-access-token": global.token } })
      .then(response => response.json())
      .then(responseJson => {
        setWineData(responseJson);
      });
    return <>Loading Wines</>;
  }


  return <TableList rank={props.rank} fetchWinesAndImages={props.fetchWinesAndImages} company_id={props.company_id} winedata={winedata} images={images}></TableList>
}

class TableList extends React.Component {

  constructor(props) {
    super(props);
    var rows = new Array;
    for (let i = 0; i < props.winedata.length; i++) {
      const element = props.winedata[i];
      rows[i] = {
        ...element, image: global.getImage(element)
      }
    }

    const company_id = this.props.company_id;

    const addImage = function (img, data, noreload) {
      var formData = new FormData();
      formData.append("file", data);

      var url = global.localip + "/uploadWine/" + company_id + "/" + img + "/";


      var toSend = formData
      axios.post(url, toSend, { headers: { "x-access-token": global.token } })
        .then(response => {
          if (!noreload) {
            console.log("test no reload");
            //props.fetchWinesAndImages();
          }
        })
        .catch(e => console.log(e))
    }

    const updateWine = function (newData, oldData) {
      var url = global.localip + "/wines/" + newData.id;
      console.log(newData);
      axios.post(url, { ...newData, tableData: undefined, tempvalue: undefined, serving_temperature: newData.tempvalue }, { headers: { "x-access-token": global.token } })
        .then(response => {
          //props.fetchWinesAndImages();
        })
        .catch(e => console.log(e))

    }

    function handleSnackbarClose() {
      this.setState({ openSnackbar: false, snackbarMsg: "" })
    }

    function setSnackbar(open, msg, severity) {
      console.log(this);
      this.setState({ openSnackbar: open, snackbarMsg: msg, snackbarSeverity: severity })
    }

    if (props.rank >= 1) {
      this.state = {
        winedata: props.winedata,
        images: props.images,
        columns: [
          {
            title: "Edit Wine",
            field: "edit",
            render: rowData => <EditModal setSnackbar={setSnackbar} key={uuidv4()} updateWine={updateWine} addImage={addImage} data={rowData} parent={this} winedata={this.state.winedata} setState={this.setState}></EditModal>
          }, {
            title: "image",
            field: "image",
            editComponent: rowData => global.getImage(undefined)
          }, {
            title: "EAN",
            field: "ean",
            type: "numeric"
          }, {
            title: "Headline",
            field: "headline"
          }, {
            title: "Displayname 1",
            field: "sku"
          }, {
            title: "Displayname 2",
            field: "anzeigename2"
          }, {
            title: "characteristics",
            field: "characteristics",
            render: rowData => <ScrollableColumn value={rowData.characteristics} height="200px" width="250px"></ScrollableColumn>
          }, {
            title: "consumption_recommendations",
            field: "consumption_recommendations"
          }, {
            title: "price",
            field: "price"
          }
        ],
        data: rows,
        openmodal: false,
        loading: false,
        buttontext: "Add Wine",
        errorsku: false,
        erroranzeigename2: false,
        errorean: false,
        errormanufacturer: false,
        errorsupplier: false,
        errorcharacterisitcs: false,
        errorrecommendations: false,
        errorprice: false,
        errororiginc: false,
        errororigina: false,
        errortemp: false,
        eanvalue: "",
        pricevalue: "",
        tempvalue: "",
        articlevalue: "",
        bottlecontent: "",
        openSnackbar: false,
        snackbarMsg: "",
        snackbarSeverity: ""
      }
    } else {
      this.state = {
        winedata: props.winedata,
        images: props.images,
        columns: [
          /*{
            title: "Edit Wine",
            field: "edit",
            render: rowData => <EditModal updateWine={updateWine} addImage={addImage} data={rowData} parent={this} winedata={this.state.winedata} setState={this.setState}></EditModal>
          },*/ {
            title: "image",
            field: "image",
            editComponent: rowData => global.getImage(undefined)
          }, {
            title: "EAN",
            field: "ean",
            type: "numeric"
          }, {
            title: "Displayname 1",
            field: "sku"
          }, {
            title: "Displayname 2",
            field: "anzeigename2"
          }, {
            title: "characteristics",
            field: "characteristics",
            render: rowData => <ScrollableColumn value={rowData.characteristics} height="200px" width="300px"></ScrollableColumn>
          }, {
            title: "consumption_recommendations",
            field: "consumption_recommendations"
          }, {
            title: "price",
            field: "price"
          }
        ],
        data: rows,
        openmodal: false,
        loading: false,
        buttontext: "Add Wine",
        errorsku: false,
        erroranzeigename2: false,
        errorean: false,
        errormanufacturer: false,
        errorsupplier: false,
        errorcharacterisitcs: false,
        errorrecommendations: false,
        errorprice: false,
        errororiginc: false,
        errororigina: false,
        errortemp: false,
        eanvalue: "",
        pricevalue: "",
        tempvalue: "",
        articlevalue: "",
        bottlecontent: "",
        openSnackbar: false,
        snackbarMsg: "",
        snackbarSeverity: ""
      }
    }
    this.setState = this.setState.bind(this);
  }


  closeModal() {
    this.setState({
      openmodal: false,
      eanvalue: "",
      pricevalue: "",
      tempvalue: ""
    });
  }

  render() {
    let thistemp = this;
    function handleSnackbarClose() {
      thistemp.setState({ openSnackbar: false, snackbarMsg: "" })
    }

    function setSnackbar(open, msg, severity) {
      thistemp.setState({ openSnackbar: open, snackbarMsg: msg, snackbarSeverity: severity })
    }

    let company_id = this.props.company_id;
    let fetchWinesAndImages = this.props.fetchWinesAndImages;
    let images = this.props.images;
    let wines = this.props.winedata;

    function deleteWine(data) {
      axios.delete(global.localip + "/wines/" + data.id, { headers: { "x-access-token": global.token } })
        .then((response) => {
          console.log(response);
          //fetchWinesAndImages();
        }, (error) => {
          console.log(error);
        });
    }

    function addWine(data) {
      var url = global.localip + "/wines/";
      console.log("addWine", data);
      axios.post(url, { ...data, company_id: company_id, tableData: undefined, tempvalue: undefined }, { headers: { "x-access-token": global.token } })
        .then(response => {
          fetchWinesAndImages();
        })
        .catch(e => console.log(e))
    }


    const addImage = function (img, data, noreload) {
      var formData = new FormData();
      formData.append("file", data);

      var url = global.localip + "/uploadWine/" + company_id + "/" + img + "/";


      var toSend = formData
      axios.post(url, toSend, { headers: { "x-access-token": global.token } })
        .then(response => {
          if (!noreload) {
            console.log("test no reload");
            //fetchWinesAndImages();
          }
        })
        .catch(e => console.log(e))
    }


    let tableActions;
    if (this.props.rank >= 2) {
      tableActions = [
        // {
        //   tooltip: 'Remove All Selected Wines',
        //   icon: 'delete',
        //   //onClick: (evt, data) => alert('You want to delete ' + data.length + ' rows')
        //   onClick: (evt, data) => {
        //     if (window.confirm("sure?")) {
        //       var deletedids = [];
        //       for (let i = 0; i < data.length; i++) {
        //         deleteWine(data[i])
        //         deletedids.push(data[i].id);
        //       }
        //       var arr = new Array;
        //       for (let i = 0; i < this.state.winedata.length; i++) {
        //         const element = this.state.winedata[i];
        //         if(!deletedids.includes(element.id)){
        //           arr.push({
        //             id: element.id, image: global.getImage(element), sku: element.sku, anzeigename2: element.anzeigename2, ean: element.ean, origin_area: element.origin_area, origin_country: element.origin_country, origin_region: element.origin_region, article_code: element.article_code, characteristics: element.characteristics, acidity: element.acidity,
        //             alcohol: element.alcohol, allergens: element.allergens, awards: element.awards, bottle_content: element.bottle_content, color: element.color, consumption_recommendations: element.consumption_recommendations, grape_variety: element.grape_variety,
        //             manufacturer: element.manufacturer, price: element.price, price_dose_small: element.price_dose_small, price_dose_medium: element.price_dose_medium, price_dose_large: element.price_dose_large, quality: element.quality, season: element.season,
        //             serving_temperature: element.serving_temperature, soil: element.soil, storage_life: element.storage_life, sugar: element.sugar, supplier: element.supplier, taste: element.taste, type: element.type, vintage: element.vintage, wine_categories: element.wine_categories, wine_food_categories: element.wine_food_categories
        //           })
        //         }
        //       }
        //       this.setState({
        //         data: arr
        //       })
        //     }
        //   }
        // }
      ];
    } else {
      tableActions = [];
    }

    let tableEditable;
    if (this.props.rank >= 2) {
      tableEditable = {
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            if (window.confirm("sure?")) {
              setTimeout(() => {
                {
                  deleteWine(oldData);
                  setSnackbar(true, "Wine deleted", "success")
                  var arr = new Array;
                  for (let i = 0; i < this.state.winedata.length; i++) {
                    const element = this.state.winedata[i];
                    if (element.id !== oldData.id) {
                      arr.push({
                        id: element.id, image: global.getImage(element), sku: element.sku, anzeigename2: element.anzeigename2, ean: element.ean, origin_area: element.origin_area, origin_country: element.origin_country, origin_region: element.origin_region, article_code: element.article_code, characteristics: element.characteristics, acidity: element.acidity,
                        alcohol: element.alcohol, allergens: element.allergens, awards: element.awards, bottle_content: element.bottle_content, color: element.color, consumption_recommendations: element.consumption_recommendations, grape_variety: element.grape_variety,
                        manufacturer: element.manufacturer, price: element.price, price_dose_small: element.price_dose_small, price_dose_medium: element.price_dose_medium, price_dose_large: element.price_dose_large, quality: element.quality, season: element.season,
                        serving_temperature: element.serving_temperature, soil: element.soil, storage_life: element.storage_life, sugar: element.sugar, supplier: element.supplier, taste: element.taste, type: element.type, vintage: element.vintage, wine_categories: element.wine_categories, wine_food_categories: element.wine_food_categories
                      })
                    }
                  }
                  this.setState({
                    data: arr
                  })
                }
                resolve()
              }, 1000)
            }
          }),
      };
    } else {
      tableEditable = {};
    }

    let newWineObj = {
      alcohol: 0,
      sugar: 0,
      acidity: 0,
      bottle_content: 0,
      serving_temperature: 10,
      price: 0,
      special_price: 0,
      vintage: 0,
      ean: 0,
      article_code: 0
    }


    return (
      <>
        <Snackbar open={this.state.openSnackbar} autoHideDuration={10000} onClose={handleSnackbarClose}>
          <AlertM onClose={handleSnackbarClose} severity={this.state.snackbarSeverity} >
            {this.state.snackbarMsg}
          </AlertM>
        </Snackbar>
        <Modal
          style={{ margin: "20px", overflow: "scroll" }}
          open={this.state.openmodal}
          onClose={() => {
            this.setState({ openmodal: false })
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <EditModal setSnackbar={setSnackbar} key={uuidv4()} newWine addWine={addWine} addImage={addImage} data={newWineObj} parent={this} winedata={this.state.winedata} setState={this.setState} closeModal={() => { this.setState({ openmodal: false }) }}></EditModal>
            </Grid>
          </Grid>
        </Modal>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card plain>
              <CardHeader plain color="primary">
                <h4 className={styles.cardTitleWhite}>
                  Wines
                </h4>
                <p className={styles.cardCategoryWhite}>
                  Here is a List of all Wines
                </p>
              </CardHeader>
              <CardBody>
                {this.props.rank >= 1 ? <Button variant="outlined" color="primary" onClick={() => {
                  this.setState({
                    openmodal: true
                  });
                }}>
                  Add a new Wine
                </Button> : null}
                <Alert color="warning" id="alertwinestable" style={{ display: "none" }}>
                  Placeholder
                </Alert>
                <MaterialTable
                  columns={this.state.columns}
                  data={this.state.data}
                  title="Wines"
                  actions={tableActions}
                  editable={tableEditable}
                  options={{
                    exportButton: true,
                    selection: this.props.rank >= 2,
                    pageSize: 10
                  }}
                  detailPanel={rowData => <Winedetails wine={rowData}></Winedetails>}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

function ScrollableColumn(props) {
  const value = props.value;
  const height = props.height;
  const width = props.width;

  return <div style={{ maxHeight: height, minWidth: width, overflow: "scroll" }}>{value}</div>
}

function Winedetails(props) {
  const wine = props.wine;

  return <Grid container spacing={3} style={{ margin: "10px" }}>
    <Grid item xs={12}>
      Details of wine: {wine.sku}
    </Grid>
    <Grid item xs={2}>
      <DetailsItem wine={wine} field={"article_code"}></DetailsItem>
    </Grid>
    <Grid item xs={2}>
      <DetailsItem wine={wine} field={"acidity"} label="Acidity (g/L)"></DetailsItem>
    </Grid>
    <Grid item xs={2}>
      <DetailsItem wine={wine} field={"allergens"}></DetailsItem>
    </Grid>
    <Grid item xs={2}>
      <DetailsItem wine={wine} field={"bottle_content"} label="Bottle content (ml)"></DetailsItem>
    </Grid>
    <Grid item xs={2}>
      <DetailsItem wine={wine} field={"manufacturer"}></DetailsItem>
    </Grid>
    <Grid item xs={2}>
      <DetailsItem wine={wine} field={"supplier"}></DetailsItem>
    </Grid>
    <Grid item xs={2}>
      <DetailsItem wine={wine} field={"origin_country"}></DetailsItem>
    </Grid>
    <Grid item xs={2}>
      <DetailsItem wine={wine} field={"origin_region"}></DetailsItem>
    </Grid>
    <Grid item xs={2}>
      <DetailsItem wine={wine} field={"type"}></DetailsItem>
    </Grid>
    <Grid item xs={2}>
      <DetailsItem wine={wine} field={"quality"}></DetailsItem>
    </Grid>
    <Grid item xs={2}>
      <DetailsItem wine={wine} field={"grape_variety"}></DetailsItem>
    </Grid>
    <Grid item xs={2}>
      <DetailsItem wine={wine} field={"color"}></DetailsItem>
    </Grid>
    <Grid item xs={2}>
      <DetailsItem wine={wine} field={"price"}></DetailsItem>
    </Grid>
    <Grid item xs={2}>
      <DetailsItem wine={wine} field={"special_price"}></DetailsItem>
    </Grid>
    <Grid item xs={2}>
      <DetailsItem wine={wine} field={"serving_temperature"} label="Temperature (°C)"></DetailsItem>
    </Grid>
    <Grid item xs={2}>
      <DetailsItem wine={wine} field={"sugar"} label="Sugar (g/L)"></DetailsItem>
    </Grid>
    <Grid item xs={2}>
      <DetailsItem wine={wine} field={"taste"}></DetailsItem>
    </Grid>
    <Grid item xs={2}>
      <DetailsItem wine={wine} field={"vintage"}></DetailsItem>
    </Grid>
  </Grid>
}

function DetailsItem(props) {
  const wine = props.wine;
  const field = props.field;
  const label = props.label;

  if (label !== undefined) {
    return <>{label}: {wine[field]}</>
  }

  return <>{field}: {wine[field]}</>
}
