import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import bgImage from "assets/img/sidebar-6.jpg";
import logo from "assets/img/btg_logo.png";
import logobytheglass from "assets/img/logo6.png";
import { Grid, TextField, Button } from "@material-ui/core";

var axios = require("axios");

let ps;


const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("blue");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [loggedin, setLoggedin] = React.useState(false);
  const [rank, setRank] = React.useState(0);
  const [company, setCompany] = React.useState(undefined);
  const [branch, setBranch] = React.useState(undefined);
  const [ownCustomerID, setOwnCustomerId] = React.useState(0);

  const [wines, setWines] = React.useState(undefined);
  const [email, setEmail] = React.useState("");

  if (ownCustomerID !== 0) {
    if (rest.hist.location.pathname === "/admin/dashboard") {
      rest.hist.push("/admin/ratings")
    }
  }

  const switchRoutes = (
    <Switch>
      {routes.map((prop, key) => {
        if (prop.layout === "/admin") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={() => <prop.component fetchWinesAndImages={fetchWinesAndImages} company_id={company} branch_id={branch} rank={rank} wines={wines} customer_id={ownCustomerID}></prop.component>}
              key={key}
            />
          );
        }
        return null;
      })}
      <Redirect from="/admin" to="/admin/dashboard" />
    </Switch>
  );
  const handleImageClick = image => {
    setImage(image);
  };
  const handleColorClick = color => {
    setColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  setTimeout(() => {
    if (sessionStorage.getItem("email") != null && sessionStorage.getItem("pw") != null) {
      if (document.getElementById("loginspenderusername") == null) {
        return;
      }
      document.getElementById("loginspenderusername").value = sessionStorage.getItem("email");
      document.getElementById("loginspenderpassword").value = sessionStorage.getItem("pw");
      login();
    }
  }, 500);


  global.wineObjbyWineId = function wineObjbyWineId(wineid) {
    for (let i = 0; i < wines.length; i++) {
      if (wines[i].id == wineid) {
        return wines[i];
      }
    }
    return undefined;
  }


  global.getImage = function getImage(rowData) {
    if (rowData == undefined) {
      return <>
        <input type="file" id="wineimgupload" title="Wine Image" onChange={(e) => {
          document.getElementById("wineimgpreview").src = URL.createObjectURL(e.target.files[0]);
        }}></input>
        <img id="wineimgpreview" style={{ maxWidth: "60%", maxHeight: "200px", marginTop: "20px" }}></img>
      </>
    } else {
      let src = global.localip + "/uploads/" + rowData.company_id + "/" + rowData.ean + ".png";
      return <img src={`${src}?${Date.now()}`} style={{ maxWidth: "200px", maxHeight: "200px" }}></img>;
    }
  }


  function login() {
    try {
      var email = document.getElementById("loginspenderusername").value;
      setEmail(email);
      var password = document.getElementById("loginspenderpassword").value;
      axios.post(global.localip + "/login", {
        email: email,
        password: password
      })
        .then(response => {
          var data = response.data;

          if (response.status === 200) {
            global.token = data.token;

            setRank(data.rank);
            if (data.rank === 3) {
              setBranch(0);
              setCompany(0);
            } else {
              setBranch(data.branche_id);
              setCompany(data.company_id);
            }
            setOwnCustomerId(data.customer_id);
            //rest.hist.push("/admin/superadmin");
            setLoggedin(true);
          } else {
            sessionStorage.setItem("email", email);
            sessionStorage.setItem("pw", password);

            alert("Falscher Nutzer / Pw");
          }
        })
    } catch (error) {
      console.log(error);
    }
    //setLoggedin(true);
  }



  function fetchWinesAndImages() {
    fetch(global.localip + "/wines/" + company, { headers: { "x-access-token": global.token } })
      .then(response => response.json())
      .then(responseJson => {
        setWines(responseJson);
      });
  }

  if (!loggedin || wines == undefined) {
    if (company != undefined) {
      fetchWinesAndImages();
    }
    return (<>
      <Grid container
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh' }}>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3>Please login to proceed...</h3>
            </Grid>
            <Grid item xs={12}>
              <TextField label="E-Mail" fullWidth id="loginspenderusername"></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField label="Password" fullWidth type="password" id="loginspenderpassword"></TextField>
            </Grid>
            <Grid item xs={12}>
              <Button onClick={login} fullWidth variant="outlined">Login</Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>
      <img src={logobytheglass} style={{ position: "absolute", bottom: "30px", right: "30px", width: "50%" }}></img>
    </>
    )
  }



  let filteredRoutes = [];
  let logoText = "";
  let logoText2 = "Winekiosk";
  if (rank == 3) {
    filteredRoutes.push(routes[0]);
    filteredRoutes.push(routes[6]);
    //filteredRoutes.push(routes[9]);
    filteredRoutes.push(routes[11]);
    filteredRoutes.push(routes[12]);
    filteredRoutes.push(routes[5]);
    logoText = "SuperAdmin";
  } else if (rank == 2) {
    filteredRoutes.push(routes[0]);
    filteredRoutes.push(routes[1]);
    filteredRoutes.push(routes[2]);
    filteredRoutes.push(routes[3]);
    filteredRoutes.push(routes[4]);
    //filteredRoutes.push(routes[10]);
    filteredRoutes.push(routes[11]);
    filteredRoutes.push(routes[13]);
    filteredRoutes.push(routes[5]);
    logoText = "Administrator";
  } else if (rank == 1) {
    filteredRoutes.push(routes[0]);
    filteredRoutes.push(routes[1]);
    filteredRoutes.push(routes[2]);
    filteredRoutes.push(routes[4]);
    //filteredRoutes.push(routes[7]);
    filteredRoutes.push(routes[8]);
    filteredRoutes.push(routes[11]);
    filteredRoutes.push(routes[5]);
    logoText = "Manager";
  } else if (rank == 0) {
    filteredRoutes.push(routes[0]);
    filteredRoutes.push(routes[1]);
    filteredRoutes.push(routes[2]);
    filteredRoutes.push(routes[7]);
    filteredRoutes.push(routes[8]);
    filteredRoutes.push(routes[14]);
    filteredRoutes.push(routes[5]);
    logoText = "Excecutive";
  } else {
    filteredRoutes.push(routes[2]);
    filteredRoutes.push(routes[5]);
    logoText = "Customer";
  }



  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={filteredRoutes}
        logoText={logoText}
        logoText2={logoText2}
        logoText3={email}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>

        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes}</div>
        )}
        {getRoute() ? <Footer routes={filteredRoutes} /> : null}

      </div>
    </div>
  );
}
