import { Grid, TextField, Select, MenuItem, FormControl, InputLabel, Button, Snackbar } from '@material-ui/core';
import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import BackgroundLoader from './BackgroundLoader';
var axios = require("axios");


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

//Fetches Settings of a Branche and provides a view to configure these.
function BranchSettings(props) {
    const [branch, setBranch] = React.useState(undefined);
    const [file, setFile] = React.useState(undefined);
    const [fileprev, setFilePrev] = React.useState(undefined);
    const [bg, setBg] = React.useState(undefined);
    const [bgprev, setBgPrev] = React.useState(undefined);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [snackbarMsg, setSnackbarMsg] = React.useState("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState("");
    const [urltime, setUrlTime] = React.useState(new Date().getTime())


    const branch_id = props.branch_id;
    const company_id = props.company_id;

    function handleSnackbarClose() {
        setOpenSnackbar(false);
        setSnackbarMsg("");
    }

    function setSnackbar(open, msg, severity) {
        setOpenSnackbar(open);
        setSnackbarMsg(msg);
        setSnackbarSeverity(severity);
    }

    //ftech and set branch
    if (branch === undefined) {
        fetch(global.localip + "/branches/" + company_id, { headers: { "x-access-token": global.token } })
            .then(response => response.json())
            .then(responseJson => {
                for (let i = 0; i < responseJson.length; i++) {
                    const element = responseJson[i];
                    if (element.id == branch_id) {
                        setBranch(element);
                    }
                }
            });
        return "Loading";
    }

    //change field of branch obj
    function changefieldBranch(field, value) {
        let branchc = JSON.parse(JSON.stringify(branch));
        branchc[field] = value;

        setBranch(branchc);
    }

    //update branch settings
    function changeSettings() {
        addImage();
        axios.post(global.localip + "/branches/" + branch_id, {
            ...branch, updated_at: undefined, created_at: undefined
        }, { headers: { "x-access-token": global.token } })
            .then((response) => {
                // console.log(response);
                setSnackbar(true, "Branchsettings changed successfully", "success")
            }, (error) => {
                console.log(error);
                setSnackbar(true, "Branchsettings error", "error")
            });
    }

    //Image upload
    const addImage = function () {
        //Update changed / set files
        if (file != undefined) {
            var formData = new FormData();
            let data = file;
            formData.append("file", data);

            var url = global.localip + "/uploadLogo/" + company_id + "/" + branch_id + "/";

            var toSend = formData
            axios.post(url, toSend, { headers: { "x-access-token": global.token } })
                .then(response => {

                })
                .catch(e => {
                    setSnackbar(true, "Branchsettings image error", "error")
                })
        }
        if (bg != undefined) {
            var formData = new FormData();
            let data = bg;

            formData.append("file", data);

            var url = global.localip + "/uploadBg/" + company_id + "/" + branch_id + "/1";

            var toSend = formData
            axios.post(url, toSend, { headers: { "x-access-token": global.token } })
                .then(response => {

                })
                .catch(e => {
                    setSnackbar(true, "Branchsettings image error", "error")
                })
        }
    }


    return (
        <Grid container spacing={4}>
            <Snackbar open={openSnackbar} autoHideDuration={10000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} >
                    {snackbarMsg}
                </Alert>
            </Snackbar>
            <Grid item xs={3}>
                <TextField variant="outlined" fullWidth label="Branchname" value={branch.name} onChange={(e) => {
                    changefieldBranch("name", e.target.value);
                }}></TextField>
            </Grid>
            <Grid item xs={3}>
                <TextField variant="outlined" multiline fullWidth label="Description" value={branch.description} onChange={(e) => {
                    changefieldBranch("description", e.target.value);
                }}></TextField>
            </Grid>
            <Grid item xs={2}>
                <TextField variant="outlined" type={"number"} fullWidth label="Service Code" value={branch.service_code} onChange={(e) => {
                    changefieldBranch("service_code", e.target.value);
                }}></TextField>
            </Grid>
            <Grid item xs={2}>
                <TextField variant="outlined" type={"number"} fullWidth label="Service Pin" value={branch.service_password} onChange={(e) => {
                    changefieldBranch("service_password", e.target.value);
                }}></TextField>
            </Grid>
            <Grid item xs={2}>
                <FormControl>
                    <InputLabel>Default language</InputLabel>
                    <Select value={branch.language} onChange={(e) => changefieldBranch("language", e.target.value)}>
                        <MenuItem value={"en"}>English</MenuItem>
                        <MenuItem value={"de"}>German</MenuItem>
                        <MenuItem value={"fr"}>French</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <FormControl>
                    <InputLabel>Currency</InputLabel>
                    <Select value={branch.currency} onChange={(e) => changefieldBranch("currency", e.target.value)}>
                        <MenuItem value={"€"}>EUR</MenuItem>
                        <MenuItem value={"$"}>USD</MenuItem>
                        <MenuItem value={"CHF"}>CHF</MenuItem>
                        <MenuItem value={"CZK"}>CZK</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            {/* <Grid item xs={2}>
                <FormControl>
                    <InputLabel>Wineinfo</InputLabel>
                    <Select value={branch.wineinfoon} onChange={(e) => changefieldBranch("wineinfoon", e.target.value)}>
                        <MenuItem value={0}>no</MenuItem>
                        <MenuItem value={1}>yes</MenuItem>
                    </Select>
                </FormControl>
            </Grid> */}
            <Grid item xs={2}>
                <FormControl>
                    <InputLabel>Login Guest</InputLabel>
                    <Select value={branch.login_method.substring(1, 2)} onChange={(e) => changefieldBranch("login_method", branch.login_method.substring(0, 1) + e.target.value + branch.login_method.substring(2, 3))}>
                        <MenuItem value={"0"}>no</MenuItem>
                        <MenuItem value={"1"}>yes</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <FormControl>
                    <InputLabel>Login scanner</InputLabel>
                    <Select value={branch.login_method.substring(0, 1)} onChange={(e) => changefieldBranch("login_method", e.target.value + branch.login_method.substring(1, 3))}>
                        <MenuItem value={"0"}>no</MenuItem>
                        <MenuItem value={"1"}>yes</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <FormControl>
                    <InputLabel>Touch start</InputLabel>
                    <Select value={branch.login_method.substring(2, 3)} onChange={(e) => changefieldBranch("login_method", branch.login_method.substring(0, 2) + e.target.value)}>
                        <MenuItem value={"0"}>no</MenuItem>
                        <MenuItem value={"1"}>yes</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid container item xs={6}>
                <Grid item xs={12}>
                    Logo
                </Grid>
                <Grid item xs={12}>
                    <input type="file" accept="image/png" id="filelogo" onChange={(e) => {
                        // console.log(e.target.files);
                        if (e.target.files.length > 0) {
                            var file = e.target.files[0];
                            const reader = new FileReader();

                            reader.readAsDataURL(file)
                            reader.addEventListener("load", function () {
                                //addImage(file)
                                setFile(file);
                                setFilePrev(reader.result);
                            }, false);
                        }

                    }}></input>
                    <img src={fileprev != undefined ? fileprev : global.localip + "/uploads/" + company_id + "/" + branch_id + "/logo.png"} style={{ maxWidth: "50%" }}></img>
                </Grid>
            </Grid>
            <Grid container item xs={6}>
                <Grid item xs={12}>
                    Background
                </Grid>
                <Grid item xs={9}>
                    <input type="file" accept="image/png, image/jpeg" id="filebg" onChange={(e) => {
                        // console.log(e.target.files);
                        if (e.target.files.length > 0) {
                            var file = e.target.files[0];
                            const reader = new FileReader();

                            reader.readAsDataURL(file)
                            reader.addEventListener("load", function () {
                                //addImage(file)
                                setBg(file);
                                setBgPrev(reader.result);
                            }, false);
                        }

                    }}></input>
                    {bgprev != undefined ? <img src={bgprev} style={{ maxWidth: "50%" }}></img> : <BackgroundLoader link={global.localip + "/uploads/" + company_id + "/" + branch_id + "/bg.png"} key={urltime}></BackgroundLoader>}
                </Grid>
                <Grid item xs={2}>
                    <Button variant="outlined" onClick={() => {
                        axios.post(global.localip + "/deleteBg/" + company_id + "/" + branch_id, { headers: { "x-access-token": global.token } })
                            .then((response) => {
                                setUrlTime(new Date().getTime())
                            }, (error) => {
                                console.log(error);
                            });
                    }}>Delete Background</Button>
                </Grid>
            </Grid>
            {props.rank === 3 ? <Grid item xs={2}>
                <Button variant="outlined" color="secondary" onClick={() => {
                    if (window.confirm("Are you sure to delete the the company?")) {
                        if (window.confirm("Are you really really sure to delete the company?")) {
                            axios.delete(global.localip + "/branches/" + branch_id, { headers: { "x-access-token": global.token } })
                                .then((response) => {
                                    if (props.fetchBranches !== undefined) {
                                        props.fetchBranches();
                                    }
                                }, (error) => {
                                    console.log(error);
                                });
                        }
                    }
                }}>Delete Branch</Button>
            </Grid> : null}
            <Grid item xs={12}>
                <Button color="primary" onClick={changeSettings} variant="outlined">Change Branchsettings</Button>
            </Grid>
        </Grid>
    )
}

export default BranchSettings
