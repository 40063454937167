import React from 'react'
import TableList from 'views/CustomerList/CustomerList';
var axios = require("axios");

//Add, edit, delete customers
function Customers(props) {
    const company_id = props.company_id;
    const [customers, setCustomers] = React.useState(undefined);
    const [company, setCompany] = React.useState(undefined);

    //ensure company_id
    if (company_id == undefined) {
        return "No Company ID";
    }

    //fetch all customers of company
    function fetchCustomers() {
        fetch(global.localip + "/customers/" + company_id, { headers: { "x-access-token": global.token } })
            .then(response => response.json())
            .then(responseJson => {
                setCustomers(responseJson);
            });
    }

    //fetch company
    function fetchCompany() {
        fetch(global.localip + "/companies/", { headers: { "x-access-token": global.token } })
            .then(response => response.json())
            .then(responseJson => {
                //filter companies
                for (let i = 0; i < responseJson.length; i++) {
                    const element = responseJson[i];
                    if (element.id === company_id) {
                        setCompany(element);
                    }
                }
            });
    }

    if (customers == undefined) {
        fetchCustomers();
        return <>Loading Customers</>;
    }

    if (company == undefined) {
        fetchCompany();
        return <>Loading Company</>;
    }

    //global functions to use in child components
    global.addCustomers = function (data) {
        for (let i = 0; i < data.length; i++) {
            global.addCustomer(data[i], false)
        }
        setTimeout(() => {
            fetchCustomers();
        }, 3000);
    }

    //global functions to use in child components
    global.deleteCustomer = function (id) {
        // console.log(id);
        fetch(global.localip + "/customers/" + id, { method: "DELETE", headers: { "x-access-token": global.token } })
        for (let i = 0; i < customers.length; i++) {
            const element = customers[i];
            if (element.id == id) {
                customers.splice(i, 1);
            }
        }
        setTimeout(() => {
            fetchCustomers();
        }, 2000);
    }

    //global functions to use in child components
    global.updateCustomer = function (newData) {
        for (let i = 0; i < customers.length; i++) {
            const element = customers[i];
            if (element.id == newData.id) {
                try {
                    if (newData.birth == null || newData.birth == undefined) {
                        newData.birth = new Date().toISOString().slice(0, 19).replace("T", " ");
                    }
                    if (newData.birth != null && newData.birth != undefined) {
                        newData.birth = new Date(newData.birth);
                        newData.birth.setHours(newData.birth.getHours() + 1);
                        newData.birth = new Date(newData.birth).toISOString().slice(0, 19).replace("T", " ");
                    }
                    customers[i].name = newData.name
                    customers[i].customer_id = newData.customer_id
                    customers[i].surname = newData.surname
                    customers[i].active = newData.active
                    customers[i].email = newData.email
                    customers[i].birth = newData.birth
                    // console.log(newData);
                    axios.post(global.localip + "/customers/" + newData.id, {
                        company_id: company_id, ...newData
                    }, { headers: { "x-access-token": global.token } })
                        .then((response) => {
                            fetchCustomers();
                        }, (error) => {
                            console.log(error);
                        });
                } catch (error) {

                }
            }
        }
    }
    //global functions to use in child components
    global.addCustomer = function (data, reload) {
        if (data.customer_id == undefined) {
            data.customer_id = Math.floor(Math.random() * 999999999999) + 1111111111111
        }
        if (data.active == undefined) {
            data.active = 1;
        }
        if (data.birth == undefined) {
            data.birth = "2020-01-01";
        }


        try {
            data.birth = new Date(data.birth.split(".")[2] + "-" + data.birth.split(".")[1] + "-" + data.birth.split(".")[0]);
        } catch (error) {
            data.birth = new Date(2020, 0, 1);
            // console.log(data.birth);
        }

        if (data.birth.getTime() !== data.birth.getTime()) {
            data.birth = new Date(2020, 0, 1);
            // console.log(data.birth);
        }


        data.birth.setHours(data.birth.getHours() + 2);
        data.birth = data.birth.toISOString().slice(0, 19).replace("T", " ");

        axios.post(global.localip + "/customers/", {
            company_id: company_id, ...data, createLogin: true
        }, { headers: { "x-access-token": global.token } })
            .then((response) => {
                fetchCustomers();
            }, (error) => {
                console.log(error);
            });
    }
    return (
        <div>
            <TableList customerdata={customers} company={company}></TableList>
        </div>
    )
}

export default Customers
