import React from 'react'

function BackgroundLoader(props) {
    const [link, setLink] = React.useState(props.link);
    const [done, setDone] = React.useState(false);

    function UrlExists(url) {
        var http = new XMLHttpRequest();
        http.open('HEAD', url, false);

        try {
            http.send();
        } catch (error) {
            return false;
        }

        return http.status != 404 && (http.responseURL != window.serverurl + "/");
    }

    // console.log("Link", link);

    if (link === undefined) {
        return null;
    }

    if (!done) {
        if (UrlExists(link)) {
            setDone(true)
        } else if (UrlExists(link.replace(".png", ".jpg"))) {
            setLink(link.replace(".png", ".jpg"));
            setDone(true);
        } else {
            setDone(true);
            setLink(undefined);
        }

        return "Loading Background";
    }

    return (
        <img src={link} style={{ maxWidth: "50%" }}>

        </img>
    )
}

export default BackgroundLoader
