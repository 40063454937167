import React, { useEffect, useState } from 'react'
import axios from 'axios'


import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { AccessTime, AcUnit, AddAlarm, AddCircle, Battery20, BatteryFull, Block, BrightnessHigh, BrightnessLow, Close, DataUsage, FiberNew, HotTub, Info, Lock, LockOpen, RemoveCircle, Save, Settings, Whatshot } from '@material-ui/icons';
import { Button, Tabs, Tab, Box, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, MenuItem, Select, Slider, Typography, Checkbox } from '@material-ui/core';
import LocalBar from '@material-ui/icons/LocalBar';

import BottelIconEmpty from './images/bottle_empty.png'
import BottelIconFull from './images/bottle_full.png'

//import TEXTS from "./languages.json";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const BootstrapDialog = Dialog;

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    size="large"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <Close fontSize="large" />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const TapSettingsDialog = (props) => {

    const { tap, handleClose, open, setFetchAll, API_PATH, calibartion_time } = props;

    const [calAmount, setCalAmount] = useState(tap.cal_ml);
    const [calTime, setCalTime] = useState(calibartion_time);
    const [pourAmount, setPourAmount] = useState(tap.pour_amount);
    const [residue, setResidue] = useState(tap.residue);
    const [locked, setLocked] = useState(props.tap.locked !== 0 ? true : false);

    // const handleLock = (event) => {
    //     setLocked(event.target.checked);
    // };

    // const handleChangeCalTime = (event, newValue) => {
    //     setCalTime(newValue);
    // };
    const handleChangeCalAmount = (event, newValue) => {
        setCalAmount(newValue);
    };
    const handleChangePourAmount = (event, newValue) => {
        setPourAmount(newValue);
    };
    const handleChangeResidue = (event, newValue) => {
        setResidue(newValue);
    };

    const handleSubmit = () => {
        let newTap = { ...tap }
        newTap.locked = locked ? 1 : 0
        newTap.residue = residue
        newTap.pour_amount = pourAmount
        newTap.cal_ml = calAmount
        newTap.cal_time = calTime
        delete newTap.id
        delete newTap.created_at
        delete newTap.wine
        delete newTap.counted_pours
        delete newTap.isEmpty

        axios.post(API_PATH + 'taps/' + tap.id, { ...newTap }, { headers: { "x-access-token": global.token } }).then(() => {
            setFetchAll(true)
            // props.setOpenSnackbar(true)
            // props.setSnackbarSeverity("success")
            // props.setSnackbarText(props.TEXTS[props.currentLang].backoffice1.snackbar.bo_saved)
            handleClose()
        })
    }

    return <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open === "3" + tap.side.toString() ? true : false}
    >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            <Typography variant="h3" >
                {props.TEXTS[props.currentLang].backoffice1.tap_settings.headline}
            </Typography>
            <Typography variant="h4" >
                Cooler {props.index + 1} | Position {tap.side === 0 ? props.TEXTS[props.currentLang].backoffice1.cooler_left : props.TEXTS[props.currentLang].backoffice1.cooler_right}
            </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
            <Grid container spacing={4} >
                <Grid item xs={8} >
                    <Typography variant="h4" align="left" >
                        {props.TEXTS[props.currentLang].backoffice1.tap_settings.lock}
                    </Typography>
                </Grid>
                <Grid item align="center" xs={4} >
                    <Button
                        value="check"
                        selected={locked}
                        onClick={() => {
                            setLocked(!locked);
                        }}
                    >
                        {locked ? <Lock style={{ fontSize: "80px", color: 'red' }} /> : <LockOpen style={{ fontSize: "80px", color: 'green' }} />}
                    </Button>
                </Grid>
                <Grid item align="center" xs={12} >
                    <Typography variant="h4" align="left" >
                        {props.TEXTS[props.currentLang].backoffice1.tap_settings.pour_amount}
                    </Typography>
                </Grid>
                <Grid item align="center" xs={12} >
                    <LocalBar fontSize="large" />
                    <Typography variant="h3" align="center" >
                        {pourAmount} ml
                    </Typography>
                </Grid>
                <Grid container item align="center" xs={12} >
                    <Grid item xs={1}>
                        <RemoveCircle fontSize="large" />
                    </Grid>
                    <Grid item xs={10}>
                        <Slider
                            step={5}
                            min={5}
                            max={100}
                            name={tap.id.toString()}
                            aria-label="bottleContent"
                            valueLabelDisplay="auto"
                            value={pourAmount}
                            onChange={handleChangePourAmount}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <AddCircle fontSize="large" />
                    </Grid>
                </Grid>
                <Grid item align="center" xs={12} >
                    <Typography variant="h4" align="left" >
                        {props.TEXTS[props.currentLang].backoffice1.tap_settings.residue}
                    </Typography>
                    <Typography variant="subtitle2" align="left" >
                        {props.TEXTS[props.currentLang].backoffice1.tap_settings.residue_info}
                    </Typography>
                </Grid>
                <Grid item align="center" xs={12} >
                    <Block fontSize="large" />
                    <Typography variant="h3" align="center" >
                        {residue} ml
                    </Typography>
                </Grid>
                <Grid container item align="center" xs={12} >
                    <Grid item xs={1}>
                        <RemoveCircle fontSize="large" />
                    </Grid>
                    <Grid item xs={10}>
                        <Slider
                            step={5}
                            min={5}
                            max={100}
                            name={tap.id.toString()}
                            aria-label="bottleContent"
                            valueLabelDisplay="auto"
                            value={residue}
                            onChange={handleChangeResidue}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <AddCircle fontSize="large" />
                    </Grid>
                </Grid>
                <Grid item align="center" xs={12} >
                    <Typography variant="h4" align="left" >
                        {props.TEXTS[props.currentLang].backoffice1.tap_settings.cal}
                    </Typography>
                    <Typography variant="subtitle2" align="left" >
                        {props.TEXTS[props.currentLang].backoffice1.tap_settings.cal_info}
                    </Typography>
                </Grid>
                <Grid item align="center" xs={12} >
                    <Typography variant="h3" align="center" >
                        {calAmount} ml
                    </Typography>
                </Grid>
                <Grid container item align="center" xs={12} >
                    <Grid item xs={1}>
                        <RemoveCircle fontSize="large" />
                    </Grid>
                    <Grid item xs={10}>
                        <Slider
                            step={1}
                            min={10}
                            max={300}
                            name={tap.id.toString()}
                            aria-label="bottleContent"
                            valueLabelDisplay="auto"
                            value={calAmount}
                            onChange={handleChangeCalAmount}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <AddCircle fontSize="large" />
                    </Grid>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button autoFocus fullWidth onClick={handleSubmit} variant="outlined" startIcon={<Save style={{ fontSize: "40px" }} />}>
                <Typography variant="h5" align="center" >
                    {props.TEXTS[props.currentLang].backoffice1.tap_settings.save_button}
                </Typography>
            </Button>
        </DialogActions>
    </BootstrapDialog>
}


const NewBottleDialog = (props) => {
    const { API_PATH, tapArray, wines, tap, handleClose, open, setFetchAll, cool } = props;
    const [wineInTap, setWineInTap] = useState(tap.wine);
    const [bottleContent, setBottleContent] = useState(tap.wine.bottle_content);
    const [coolerTemp, setCoolerTemp] = useState(0);
    const [bottleChanged, setBottleChanged] = useState(false);

    const handleChangeBottleContent = (event, newValue) => {
        setBottleContent(newValue);
    };

    const handleChange = (id) => {
        const found = wines.find(wine => wine.id === id);
        setWineInTap({ ...found })
        setBottleChanged(true)
        setCoolerTemp(found.serving_temperature)
    };

    const handleSubmit = () => {
        let newTap = { ...tap }
        newTap.wine_id = wineInTap.id
        newTap.bottle_ml = bottleContent

        let temperature = 0
        let temp1 = 0
        let temp2 = 0
        for (const tap of tapArray) {
            if (tap.cooler_id === cool.id) {
                if (tap.side === newTap.side) {
                    temp1 = coolerTemp
                }
                else {
                    temp2 = tap.wine.serving_temperature
                }
            }
        }
        if (bottleChanged) {
            temperature = Math.floor((temp1 + temp2) / 2)
        }
        else temperature = cool.temperature
        delete newTap.wine
        delete newTap.id
        delete newTap.created_at
        delete newTap.counted_pours
        delete newTap.isEmpty
        axios.post(API_PATH + 'taps/', { ...newTap }, { headers: { "x-access-token": global.token } }).then((response) => {
            axios.post(API_PATH + 'cooler/' + newTap.cooler_id, { [newTap.side === 0 ? 'tapL_id' : 'tapR_id']: response.data.insertId, temperature: temperature }, { headers: { "x-access-token": global.token } }).then(() => {
                setFetchAll(true)
                // props.setOpenSnackbar(true)
                // props.setSnackbarSeverity("success")
                // props.setSnackbarText(props.TEXTS[props.currentLang].backoffice1.new_bottle.snackbar.bottle_changed)
                setBottleChanged(false)
                handleClose()
            })
        })
    }

    let foundWine = false
    let scanning = false
    let wineEAN_scan = "";

    useEffect(() => {
        if (open === "20" || open == "21") {
            document.addEventListener("keydown", wineScanner);
        }
        else {
            document.removeEventListener("keydown", wineScanner)
            wineEAN_scan = ""
        }
    }, [open]);

    function wineScanner(e) {
        scanning = true
        if (isNaN(e.key)) return;
        if (wineEAN_scan.length < 13) {
            wineEAN_scan += e.key;
        }
        else wineEAN_scan = ""

        if (wineEAN_scan.length === 13) {
            let found = wines.find(wine => wine.ean.toString() === wineEAN_scan.toString())
            setWineInTap({ ...found })
            setCoolerTemp(found.serving_temperature)
            // props.setOpenSnackbar(true)
            // props.setSnackbarSeverity("success")
            // props.setSnackbarText(props.TEXTS[props.currentLang].backoffice1.new_bottle.snackbar.wine_found)
            wineEAN_scan = ""
        }
    }

    return <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open === "2" + tap.side.toString() ? true : false}
    >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            <Typography variant="h3" >
                {props.TEXTS[props.currentLang].backoffice1.new_bottle.headline}
            </Typography>
            <Typography variant="h4" >
                Cooler {props.index + 1} | Position {tap.side === 0 ? props.TEXTS[props.currentLang].backoffice1.cooler_left : props.TEXTS[props.currentLang].backoffice1.cooler_right}
            </Typography>

        </BootstrapDialogTitle>
        <DialogContent dividers>
            <Grid container spacing={4} >
                <Grid item align="center" xs={12} >
                    <Typography variant="h4" align="left"  >
                        {props.TEXTS[props.currentLang].backoffice1.new_bottle.choose_wine}
                    </Typography>
                    <FormControl fullWidth>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={wineInTap.id}
                            onChange={(event) => handleChange(event.target.value)}
                        >
                            {wines.map((wine) => (
                                <MenuItem style={{ borderStyle: "solid", borderWidth: "1px", borderColor: "#757575de" }} key={wine.id} disabled={wine.id === wineInTap.id ? true : false} value={wine.id}>
                                    <Grid container spacing={4} >
                                        <Grid item xs={10} >
                                            <Typography variant="h5" align="left" >
                                                {wine.sku}
                                            </Typography>
                                            <Typography variant="h6" align="left" >
                                                {wine.supplier}
                                            </Typography>
                                            <Typography variant="h6" align="left" >
                                                {wine.manufacturer}
                                            </Typography>
                                            <Typography variant="h6" align="left" >
                                                {wine.origin_country}
                                            </Typography>
                                            <Typography variant="h6" align="left" >
                                                {wine.bottle_content} ml
                                            </Typography>
                                        </Grid>
                                        <Grid item align="center" xs={2} >
                                            <img height="150vh" src={API_PATH + 'uploads/' + wine.company_id + '/' + wine.ean + '.png'} ></img>
                                        </Grid>
                                    </Grid>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item align="center" xs={12} >
                    <Typography variant="h4" align="left" >
                        {props.TEXTS[props.currentLang].backoffice1.new_bottle.bottle_content}
                    </Typography>
                </Grid>
                <Grid item align="center" xs={12} >
                    <Typography variant="h4" align="center" >
                        {bottleContent} ml
                    </Typography>
                </Grid>
                <Grid container item align="center" xs={12} >
                    <Grid item xs={1}>
                        <img src={BottelIconEmpty} height={50} />
                    </Grid>
                    <Grid item xs={10}>
                        <Slider
                            marks={[{ value: 375, label: '375ml' }, { value: 750, label: '750ml' }, { value: 1000, label: '1000ml' }]}
                            min={100}
                            max={1500}
                            name={tap.id.toString()}
                            aria-label="bottleContent"
                            valueLabelDisplay="auto"
                            value={bottleContent}
                            onChange={handleChangeBottleContent}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <img src={BottelIconFull} height={50} />
                    </Grid>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button autoFocus fullWidth onClick={handleSubmit} variant="outlined" startIcon={<Save style={{ fontSize: "40px" }} />}>
                <Typography variant="h5" align="center" >
                    {props.TEXTS[props.currentLang].backoffice1.new_bottle.save_button}
                </Typography>
            </Button>
        </DialogActions>
    </BootstrapDialog>
}


export default function Backoffice(props) {
    const { company_id, branche_id, device_id, TEXTS, currentLang, API_PATH, cooler, taps, setTapArray, tapArray, wines, setCooler, /*setTimerActive,*/ setFetchAll, /*changeTemp, changeBright,*/ calibartion_time/*, setSnackbarSeverity, setOpenSnackbar, setSnackbarText*/ } = props;


    const [open, setOpen] = useState(0);
    const [value, setValue] = useState(0);
    const [selectedCooler, setSelectedCooler] = useState();

    useEffect(() => {
        //setTimerActive(true)
        //setFetchAll(true)
    }, []);

    const handleOpen = (id) => {
        setOpen(id)
    }

    const handleClose = () => setOpen(0);

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeBright = (event, brightness, id) => {
        let updatedList = cooler.map((cool) => {
            if (cool.id === id) {
                const objectvalue = {
                    ...cool,
                    ['brightness']: brightness,
                };
                //changeBright(cool.can_id, brightness)
                setSelectedCooler({ ...cool })
                return objectvalue;
            }
            return cool;
        });
        setCooler(updatedList);
    };

    const handleChangeTemp = (event, temperature, id) => {
        let updatedList = cooler.map((cool) => {
            if (cool.id === id) {
                const objectvalue = {
                    ...cool,
                    ['temperature']: temperature,
                };
                //changeTemp(cool.can_id, temperature)
                setSelectedCooler({ ...cool })
                return objectvalue;
            }
            return cool;
        });
        setCooler(updatedList);
    };

    const handleSubmit = () => {
        let newCooler = { ...selectedCooler }
        // Update Cooler
        delete newCooler.id
        delete newCooler.created_at
        delete newCooler.updated_at

        axios.post(API_PATH + 'cooler/' + selectedCooler.id, { ...newCooler }, { headers: { "x-access-token": global.token } }).then((resp) => {
            // props.setOpenSnackbar(true)
            // props.setSnackbarSeverity("success")
            // props.setSnackbarText(TEXTS[currentLang].backoffice1.snackbar.bo_saved)
            setFetchAll(true)
        })
    }

    const tapsWidth = 100 / props.tapArray.length

    return (
        <div >
            {/* <Typography align="center" fontSize={20} style={{ marginTop: "-50px" }}>
                Kiosk ID: {company_id}-{branche_id}-{device_id}
            </Typography> */}
            {/* <Typography fontSize={180} align="center">
                {TEXTS[currentLang].backoffice1.headline}
            </Typography> */}
            <br></br>
            {cooler.length !== 0 ?
                <div>
                    <Tabs value={value}
                        onChange={handleChangeTab}
                        centered
                        style={{ width: "100%" }}
                        aria-label="basic tabs" >
                        {
                            cooler.map((cool, index) => (
                                cool.active === 1 ?
                                    <Tab
                                        style={{ width: tapsWidth + "%", height: "20px" }}
                                        key={"TC" + cool.id}
                                        id={`simple-tab-${index}`}
                                        aria-controls={`simple-tabpanel-${index}`}
                                        label={
                                            <Typography variant="h5">
                                                Cooler {cool.can_id}
                                            </Typography>
                                        }
                                    />
                                    : null
                            ))
                        }
                    </Tabs>
                    {cooler.map((cool, index) => (
                        cool.active === 1 ?
                            <TabPanel key={"C" + cool.id} value={value} index={index} style={{ width: "100%" }} >
                                <Box component="div" sx={{ p: 2, border: '1px solid #757575de' }} style={{ padding: "30px" }} >
                                    <Grid container alignContent="center" justifyContent="center" spacing={2} >
                                        {tapArray.map((tap) => (
                                            cool.id === tap.cooler_id ?
                                                //// Wenn neuer Cooler, Tap mit leeren Daten anzeigen
                                                <Grid key={"t" + tap.id} item align="center" xs >
                                                    {/* <Box key={"w" + tap.wine.id} component="div" sx={{ p: 2, border: '1px solid #757575de' }} style={tap.isEmpty === 0 ? { padding: "30px", backgroundColor: "#ff000091" } : { padding: "30px" }} > */}
                                                    <Box key={"w" + tap.wine.id} component="div" sx={{ p: 2, border: '1px solid #757575de' }} >
                                                        <Grid container alignContent="center" justifyContent="center" spacing={3} >
                                                            <Grid item align="center" xs={12} >
                                                                <Button fullWidth onClick={() => handleOpen("3" + tap.side.toString(), tap.wine, tap)} size="large" variant="outlined" startIcon={<Settings style={{ fontSize: "40px" }} />}>
                                                                    <Typography fontSize={30} align="center" style={{ padding: "10px" }} >
                                                                        {TEXTS[currentLang].backoffice1.tap_settings_button} {tap.side === 0 ? TEXTS[currentLang].backoffice1.cooler_left : TEXTS[currentLang].backoffice1.cooler_right}
                                                                    </Typography>
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                        <br />
                                                        <Grid container alignContent="center" justifyContent="center" spacing={3} >
                                                            {
                                                                tap.locked === 1 ?
                                                                    <div style={{ position: "absolute", backgroundColor: "#e0e0e0cc", width: "40vw", height: "34vh", marginLeft: 20, marginTop: 25, zIndex: 99 }} key={"img" + tap.id}><Lock style={{ height: '29.22vh', fontSize: '100px', color: 'red' }} /></div>
                                                                    : null
                                                            }
                                                            <Grid item align="center" xs={12} >
                                                                <Typography variant="h4" align="center" style={{ height: "3vh" }} >
                                                                    {tap.wine.sku}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item align="left" xs={4} >
                                                                <BarChart
                                                                    width={110}
                                                                    height={510}
                                                                    data={[{
                                                                        residue: tap.residue,
                                                                        bottle_ml: tap.bottle_ml - tap.counted_pours - tap.residue
                                                                    }]}
                                                                >
                                                                    <CartesianGrid strokeDasharray="3 3" />
                                                                    <XAxis dataKey="name" />
                                                                    <YAxis orientation="left" domain={[0, tap.bottle_ml]} />
                                                                    <Tooltip />
                                                                    <Bar dataKey="residue" stackId={tap.id} fill="#c33d3d" />
                                                                    <Bar dataKey="bottle_ml" stackId={tap.id} fill={tap.bottle_ml - tap.counted_pours - tap.residue <= 75 ? "#c33d3d" : tap.bottle_ml - tap.counted_pours - tap.residue <= 200 ? "#cab481" : "#82ca9d"} />
                                                                </BarChart>
                                                            </Grid>
                                                            <Grid item align="center" xs={4} >
                                                                <div key={"img" + tap.id}><img style={tap.isEmpty === 0 ? { height: '510px', filter: "grayscale(100%)", filter: "opacity(0.5)" } : { height: '510px' }} src={API_PATH + 'uploads/' + tap.wine.company_id + '/' + tap.wine.ean + '.png?' + new Date().getTime()} ></img></div>
                                                            </Grid>
                                                            <Grid item align="center" xs={4} >
                                                                <Grid item align="center" xs={12} >
                                                                    <Info fontSize="large" />
                                                                    <Typography variant="h5" align="center" style={{ height: "2.5vh" }} >
                                                                        {tap.bottle_ml} ml
                                                                    </Typography>
                                                                </Grid>
                                                                <br></br>
                                                                <Grid item align="center" xs={12} >
                                                                    <LocalBar fontSize="large" />
                                                                    <Typography variant="h5" align="center" style={{ height: "2.5vh" }} >
                                                                        {tap.pour_amount} ml
                                                                    </Typography>
                                                                </Grid>
                                                                <br></br>
                                                                <Grid item align="center" xs={12} >
                                                                    <Block fontSize="large" />
                                                                    <Typography variant="h5" align="center" style={{ height: "2.5vh" }} >
                                                                        {tap.residue} ml
                                                                    </Typography>
                                                                </Grid>
                                                                <br></br>
                                                                <Grid item align="center" xs={12} >
                                                                    <DataUsage fontSize="large" />
                                                                    <Typography variant="h5" align="center" style={{ height: "2.5vh" }} >
                                                                        {tap.bottle_ml - tap.residue - tap.counted_pours} ml
                                                                    </Typography>
                                                                </Grid>
                                                                <br></br>
                                                                <Grid item align="center" xs={12} >
                                                                    <AccessTime fontSize="large" />
                                                                    <Typography variant="subtitle1" align="center" style={{ height: "2.5vh" }} >
                                                                        {new Date(tap.created_at).toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric', hour: "2-digit", minute: "2-digit" })}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <br />
                                                        <Grid container alignContent="center" justifyContent="center" spacing={3} >
                                                            <Grid item align="center" xs={12} >
                                                                <Button disabled={tap.locked === 1 ? true : false} fullWidth onClick={() => handleOpen("2" + tap.side.toString(), tap.wine, tap)} size="large" variant="outlined" style={tap.isEmpty === 0 ? { color: "#c33d3d" } : {}} startIcon={<FiberNew style={{ fontSize: "40px" }} />}>
                                                                    <Typography variant="h5" align="center" >
                                                                        {TEXTS[currentLang].backoffice1.new_bottle_button}
                                                                    </Typography>
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                        <NewBottleDialog
                                                            cool={cool}
                                                            index={index}
                                                            open={open}
                                                            handleClose={handleClose}
                                                            wines={wines}
                                                            tap={tap}
                                                            tapArray={tapArray}
                                                            taps={taps}
                                                            cooler={cooler}
                                                            API_PATH={API_PATH}
                                                            setFetchAll={setFetchAll}
                                                            // setSnackbarSeverity={setSnackbarSeverity}
                                                            // setSnackbarText={setSnackbarText}
                                                            // setOpenSnackbar={setOpenSnackbar}
                                                            TEXTS={props.TEXTS}
                                                            currentLang={props.currentLang}
                                                        />
                                                        <TapSettingsDialog
                                                            index={index}
                                                            open={open}
                                                            handleClose={handleClose}
                                                            tap={tap}
                                                            setTapArray={setTapArray}
                                                            cooler={cooler}
                                                            API_PATH={API_PATH}
                                                            setFetchAll={setFetchAll}
                                                            calibartion_time={calibartion_time}
                                                            // setSnackbarSeverity={setSnackbarSeverity}
                                                            // setSnackbarText={setSnackbarText}
                                                            // setOpenSnackbar={setOpenSnackbar}
                                                            TEXTS={props.TEXTS}
                                                            currentLang={props.currentLang}
                                                        />
                                                    </Box>
                                                </Grid>
                                                : null
                                        ))}
                                        <Grid item align="center" xs={12} >
                                            <Box component="div" sx={{ p: 2, border: '1px solid #757575de' }} style={{ padding: "30px" }} >
                                                <Grid item align="center" xs={12} >
                                                    <Typography variant="h4" align="left" >
                                                        {TEXTS[currentLang].backoffice1.cooler_settings.headline}
                                                    </Typography>
                                                </Grid>
                                                <br></br>
                                                <Grid container item align="center" xs={12}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h5" align="center" >
                                                            {TEXTS[currentLang].backoffice1.cooler_settings.light} {cool.brightness}%
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <BrightnessLow fontSize="large" />
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        <Slider
                                                            step={10}
                                                            marks
                                                            min={0}
                                                            max={100}
                                                            name={cool.id.toString()}
                                                            aria-label="Brightness"
                                                            valueLabelDisplay="auto"
                                                            value={cool.brightness}
                                                            onChange={(e, val) => handleChangeBright(e, val, cool.id)} />
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <BrightnessHigh fontSize="large" />
                                                    </Grid>
                                                </Grid>
                                                <br></br>
                                                <Grid container item align="center" xs={12}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h5" align="center" >
                                                            {TEXTS[currentLang].backoffice1.cooler_settings.temperature} {cool.temperature}°C
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        < AcUnit fontSize="large" />
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        <Slider
                                                            step={1}
                                                            marks
                                                            min={6}
                                                            max={21}
                                                            name={cool.id.toString()}
                                                            aria-label="Temperature"
                                                            valueLabelDisplay="auto"
                                                            value={cool.temperature}
                                                            onChange={(e, val) => handleChangeTemp(e, val, cool.id)} />
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        < BrightnessLow fontSize="large" />
                                                    </Grid>
                                                </Grid>
                                                <br></br>
                                                <Grid item align="center" xs={12} >
                                                    <Button fullWidth onClick={handleSubmit} size="large" disabled={!selectedCooler ? true : false} variant="outlined" startIcon={<Save style={{ fontSize: "40px" }} />}>
                                                        <Typography variant="h5" align="center" >
                                                            {TEXTS[currentLang].backoffice1.cooler_settings.save_button}
                                                        </Typography>
                                                    </Button>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TabPanel >
                            : null
                    ))
                    }
                </div >
                :
                <Typography variant="h1" align="center">
                    {TEXTS[currentLang].backoffice1.no_cooler}
                </Typography>
            }

        </div >
    );
}
