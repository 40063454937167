import React from 'react'

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { ExpandMore } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import Device from './Device';
import BranchSettings from 'views/BranchSettings/BranchSettings';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import { propTypes } from 'react-bootstrap/esm/Image';

function BranchView(props) {
    const [devices, setDevices] = React.useState(undefined);
    var branch_id = props.branch_id;
    var company_id = props.company_id;

    if (branch_id == undefined) {
        return "No branch ID";
    }

    function fetchDevices() {
        fetch(global.localip + "/devices/" + branch_id, {headers: {"x-access-token": global.token}})
            .then(response => response.json())
            .then(responseJson => {
                setDevices(responseJson);
            });
    }

    if (devices == undefined) {
        fetchDevices();
        return <>Loading Devices</>;
    }

    if(props.dashboard){
        return <div>
        {devices.map((device, index) => (
            <Accordion key={index}>
                <AccordionSummary
                    expandIcon={<ExpandMore></ExpandMore>}
                >
                    <Typography>{device.name} ({device.id})</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Device device_id={device.id} wines={props.wines} fetchDevices={fetchDevices} images={props.images} rank={props.rank} branch_id={branch_id} company_id={company_id}></Device>
                </AccordionDetails>
            </Accordion>
        ))}
        </div>
    }
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card plain>
                        <CardHeader plain color="primary">
                            <h4>
                                Branch
                            </h4>
                            <p>
                                Overview over Branch and Devices
                            </p>
                        </CardHeader>
                        <CardBody>
                            {props.dashboard ? null : <BranchSettings fetchBranches={props.fetchBranches} company_id={company_id} branch_id={branch_id} rank={props.rank}></BranchSettings>}
                            <br></br>
                            {devices.map((device, index) => (
                                <Accordion key={index}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore></ExpandMore>}
                                    >
                                        <Typography>{device.name} ({device.id})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Device device_id={device.id} wines={props.wines} fetchDevices={fetchDevices} images={props.images} rank={props.rank} branch_id={branch_id} company_id={company_id}></Device>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    )
}

export default BranchView
