import React from 'react'
import { Alert } from 'reactstrap';
import { Button, Grid, Paper, TextField, Checkbox, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
var axios = require("axios");


//Add Customer view
function AddCustomer(props) {
  const [errormail, setErrorMail] = React.useState(false);
  const [cidval, setCidVal] = React.useState("");
  const [buttontext, setButtonText] = React.useState("Add Customer");
  const [loading, setLoading] = React.useState(false);
  const [company, setCompany] = React.useState(undefined);

  //fetch company if undefined
  if (company == undefined) {
    fetchCompany();
    return <>Loading Companies</>;
  }

  function fetchCompany() {
    fetch(global.localip + "/companies/", { headers: { "x-access-token": global.token } })
      .then(response => response.json())
      .then(responseJson => {
        for (let i = 0; i < responseJson.length; i++) {
          const element = responseJson[i];
          //filter companies
          if (element.id === props.company_id) {
            setCompany(element);
          }
        }
      });
  }

  //reset all Input fields
  function resetInputs() {
    setLoading(false);
    setCidVal("");
    setErrorMail(false);
    setButtonText("Added Customer");

    document.getElementById("name").value = "";
    document.getElementById("surname").value = "";
    document.getElementById("email").value = "";
    document.getElementById("birth").value = "";
    document.getElementById("customer_id").value = "";
    document.getElementById("active").checked = 1;
    setTimeout(() => {
      setButtonText("Add Customer");
    }, 2000);
  }


  function addCustomer(data) {
    //ensure data complete
    if (data.customer_id == undefined) {
      data.customer_id = Math.floor(Math.random() * 999999999999) + 1111111111111
    }
    if (data.active == undefined) {
      data.active = 1;
    }
    if (data.birth == undefined) {
      data.birth = "2020-01-01";
    }

    //create birthday and fix if neccessary
    try {
      data.birth = new Date(data.birth.split(".")[2] + "-" + data.birth.split(".")[1] + "-" + data.birth.split(".")[0]);
    } catch (error) {
      data.birth = new Date(2020, 0, 1);
    }

    if (data.birth.getTime() !== data.birth.getTime()) {
      data.birth = new Date(2020, 0, 1);
      // console.log(data.birth);
    }

    data.birth.setHours(data.birth.getHours() + 2);
    data.birth = data.birth.toISOString().slice(0, 19).replace("T", " ");

    //Post Customer to API
    axios.post(global.localip + "/customers/", {
      company_id: props.company_id, ...data
    }, { headers: { "x-access-token": global.token } })
      .then((response) => {
        resetInputs();
      }, (error) => {
        console.log(error);
      });
  }

  //View
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card plain>
            <CardHeader plain color="primary">
              <h4>
                Add a new Customer
              </h4>
              <p>
                Here you can add new Customers. Make sure all required fields(*) are filled out.
              </p>
            </CardHeader>
            <CardBody>
              <Grid container>
                <Grid item xs={12}>
                  {this !== undefined && this.closeModal !== undefined ?
                    <IconButton style={{ position: "absolute", top: "10px", right: "10px" }}>
                      <Close onClick={() => {
                        this.closeModal();
                      }}></Close>
                    </IconButton> : null}
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField fullWidth label="Name" id="name"></TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField fullWidth label="Surname" id="surname"></TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField fullWidth label="E-mail(*)" id="email" type="email" error={errormail}></TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField fullWidth label="Birthday(DD.MM.YYYY)" id="birth"></TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField fullWidth label={"Customer ID (" + company.login_code_length + " digits)"} id="customer_id" value={cidval} onChange={(e) => {
                        var val = e.target.value;
                        if (!isNaN(val)) {
                          setCidVal(val);
                        }
                      }}></TextField>
                    </Grid>
                    <Grid item xs={12}>
                      Active: <Checkbox id="active" defaultChecked={true}></Checkbox>
                    </Grid>
                    <Alert color="warning" id="alertwines" style={{ display: "none" }}>
                      Placeholder
                    </Alert>
                    <Button
                      fullWidth
                      variant="outlined"
                      onClick={() => {
                        if (loading) {
                          return;
                        }
                        setLoading(true);
                        setButtonText("Loading...");

                        var newData = {};
                        newData.name = document.getElementById("name").value;
                        newData.surname = document.getElementById("surname").value;
                        newData.email = document.getElementById("email").value;
                        newData.birth = document.getElementById("birth").value;
                        newData.customer_id = document.getElementById("customer_id").value;
                        newData.active = document.getElementById("active").checked;

                        if (newData.email.length == 0) {
                          setTimeout(() => {
                            setErrorMail(false);
                          }, 10000);
                          setLoading(false);
                          setButtonText("Add Customer");
                          setErrorMail(true);
                          return;
                        }
                        if (newData.name.length == 0) {
                          newData.name = "x";
                        }
                        if (newData.surname.length == 0) {
                          newData.surname = "x";
                        }
                        if (newData.birth.length == 0) {
                          newData.birth = undefined;
                        }
                        if (newData.customer_id.length == 0) {
                          newData.customer_id = undefined;
                        } else {
                          if (isNaN(parseInt(newData.customer_id)) || newData.customer_id.length != company.login_code_length) {
                            document.getElementById("alertwines").style.display = "block";
                            document.getElementById("alertwines").innerHTML = "The ID you entered seems not to be valid.";
                            setTimeout(() => {
                              try {
                                document.getElementById("alertwines").style.display = "none";
                              } catch (error) {

                              }
                            }, 10000);
                            setLoading(false);
                            setButtonText("Add Customer")
                            return;
                          }
                        }

                        // console.log(newData);
                        addCustomer(newData);
                        //   setTimeout(() => {
                        //     var arr = new Array;
                        //     for (let i = 0; i < this.props.customerdata.length; i++) {
                        //       const element = this.props.customerdata[i];
                        //       arr[i] = { id: element.id, customer_id: element.customer_id, active: element.active, name: element.name, surname: element.surname, email: element.email, birth: element.birth }
                        //     }
                        //     this.closeModal();
                        //     this.setState({
                        //       data: arr,
                        //       loading: false,
                        //       buttontext: "Add Customer"
                        //     })
                        //   }, 3000);
                      }}>{buttontext}</Button>
                  </Grid>
                </Grid>
              </Grid>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}

export default AddCustomer
