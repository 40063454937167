import React from 'react'
import TableListWrapper from 'views/WineList/WineList';


function Wines(props) {
    const company_id = props.company_id;

    if (company_id == undefined) {
        return "No Company ID";
    }


    return (
        <div>
            <TableListWrapper fetchWinesAndImages={props.fetchWinesAndImages} winedata={props.wines} setWines={props.setWines} images={props.images} setImages={props.setImages} company_id={props.company_id} rank={props.rank}></TableListWrapper>
        </div>
    )
}

export default Wines
