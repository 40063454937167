import React from 'react'

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { ExpandMore } from '@material-ui/icons';
import { AppBar, Typography, Tabs, Tab, Box, Grid } from '@material-ui/core';
import AllBranchesForCompany from './AllBranchesForCompany';
import Wines from 'views/FixedViews/Wines';
import Ratings from 'views/FixedViews/Ratings';
import Customers from 'views/FixedViews/Customers';
import CompanySettings from 'views/CompanySettings/CompanySettings';


function AdminView(props) {
    const [tab, setTab] = React.useState(0);
    const company_id = props.company.id;

    return (
        <div>
            <AppBar position="static" color="transparent">
                <Tabs variant="fullWidth" value={tab} fullWidth onChange={(e, val) => { setTab(val) }} aria-label="simple tabs example">
                    <Tab label="Wines" />
                    <Tab label="Ratings" />
                    <Tab label="Customers" />
                    <Tab label="Branches" />
                    <Tab label="Settings" />
                </Tabs>
            </AppBar>
            <TabPanel value={tab} index={0}>
                <Wines rank={props.rank} fetchWinesAndImages={props.fetchWinesAndImages} company_id={company_id} wines={props.wines} images={props.images}></Wines>
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <Ratings rank={props.rank} company_id={company_id} wines={props.wines} images={props.images}></Ratings>
            </TabPanel>
            <TabPanel value={tab} index={2}>
                <Customers rank={props.rank} company_id={company_id}></Customers>
            </TabPanel>
            <TabPanel value={tab} index={3}>
                <AllBranchesForCompany rank={props.rank} company_id={company_id} wines={props.wines} images={props.images}></AllBranchesForCompany>
            </TabPanel>
            <TabPanel value={tab} index={4}>
                <CompanySettings fetchCompanies={props.fetchCompanies} company_id={props.company} rank={props.rank}></CompanySettings>
            </TabPanel>
        </div>
    )
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default AdminView
