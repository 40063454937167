import React from 'react'

function ServiceCodes(props) {
    return (
        <div>
            Manage service codes for company {props.company_id}
        </div>
    )
}

export default ServiceCodes
