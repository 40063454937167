/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Dock from "@material-ui/icons/Dock";
import Bar from "@material-ui/icons/LocalBar";
import Stars from "@material-ui/icons/Stars";
import User from "@material-ui/icons/SupervisedUserCircleSharp";
// core components/views for Admin layout
import DashboardPage from "views/FixedViews/Dashboard.js";
import { Add, ExitToApp, PersonAdd, Scanner, Settings, SettingsApplications } from "@material-ui/icons";
import Logout from "views/Logout/Logout";
import SuperAdminView from "views/SuperAdmin/SuperAdminView";
import Wines from "views/FixedViews/Wines";
import Ratings from "views/FixedViews/Ratings";
import Customers from "views/FixedViews/Customers";
import AllBranchesForCompany from "views/SuperAdmin/AllBranchesForCompany";
import BranchView from "views/SuperAdmin/BranchView";
import AccountTree from '@material-ui/icons/AccountTree';
import AddCustomer from "views/FixedViews/AddCustomer";
import UserSettings from "views/FixedViews/UserSettings";
import ServiceCodes from "views/FixedViews/ServiceCodes";
import UserManagment from "views/SuperAdmin/UserManagment";
import CompanySettings from "views/CompanySettings/CompanySettings";
import BranchSettings from "views/BranchSettings/BranchSettings";
import SystemManager from "views/SystemManager/SystemManager";

// core components/views for RTL layout

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/wines",
    name: "Wines",
    rtlName: "لوحة القيادة",
    icon: Bar,
    component: Wines,
    layout: "/admin"
  },
  {
    path: "/ratings",
    name: "Ratings",
    rtlName: "لوحة القيادة",
    icon: Stars,
    component: Ratings,
    layout: "/admin"
  },
  {
    path: "/customers",
    name: "Customers",
    rtlName: "لوحة القيادة",
    icon: User,
    component: Customers,
    layout: "/admin"
  },
  {
    path: "/branches",
    name: "Branches",
    rtlName: "لوحة القيادة",
    icon: AccountTree,
    component: AllBranchesForCompany,
    layout: "/admin"
  },
  {
    path: "/logout",
    name: "Logout",
    rtlName: "لوحة القيادة",
    icon: ExitToApp,
    component: Logout,
    layout: "/admin"
  },
  {
    path: "/superadmin",
    name: "Companies",
    rtlName: "لوحة القيادة",
    icon: AccountTree,
    component: SuperAdminView,
    layout: "/admin"
  },
  {
    path: "/branchview",
    name: "Your Branch",
    rtlName: "لوحة القيادة",
    icon: AccountTree,
    component: BranchView,
    layout: "/admin"
  },
  {
    path: "/addcustomer",
    name: "Add Customer",
    rtlName: "لوحة القيادة",
    icon: PersonAdd,
    component: AddCustomer,
    layout: "/admin"
  },
  {
    path: "/users",
    name: "Users",
    rtlName: "لوحة القيادة",
    icon: PersonAdd,
    component: UserSettings,
    layout: "/admin"
  },
  {
    path: "/servicecodes",
    name: "Manage Servicecodes",
    rtlName: "لوحة القيادة",
    icon: Scanner,
    component: ServiceCodes,
    layout: "/admin"
  },
  {
    path: "/usermanagement",
    name: "Users",
    rtlName: "لوحة القيادة",
    icon: User,
    component: UserManagment,
    layout: "/admin"
  },
  {
    path: "/addthings",
    name: "Add to System",
    rtlName: "لوحة القيادة",
    icon: Add,
    component: SystemManager,
    layout: "/admin"
  },
  {
    path: "/companysettings",
    name: "Company Settings",
    rtlName: "لوحة القيادة",
    icon: SettingsApplications,
    component: CompanySettings,
    layout: "/admin"
  },
  {
    path: "/branchsettings",
    name: "Branch Settings",
    rtlName: "لوحة القيادة",
    icon: SettingsApplications,
    component: BranchSettings,
    layout: "/admin"
  }
];

export default dashboardRoutes;
