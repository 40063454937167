import React from 'react'
import Icon from '@material-ui/core/Icon';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { makeStyles } from '@material-ui/core/styles';
import CardBody from 'components/Card/CardBody';

const useStyles = makeStyles(styles);


function DashboardManager(props) {
    const [devices, setDevices] = React.useState(undefined);
    const [ratings, setRatings] = React.useState(undefined);
    const [customers, setCustomers] = React.useState(undefined);
    const [wines, setWines] = React.useState(undefined);
    const classes = useStyles();

    //fetch Devices
    if (devices === undefined) {
        fetch(global.localip + "/devices/" + props.branch_id, {headers: {"x-access-token": global.token}})
            .then(response => response.json())
            .then(responseJson => {
                setDevices(responseJson);
            });
        return "Loading";
    }

    //fetch Ratings
    if (ratings === undefined) {
        fetch(global.localip + "/poursCompany/" + props.company_id, {headers: {"x-access-token": global.token}})
            .then(response => response.json())
            .then(responseJson => {
                setRatings(responseJson);
            });
        return "Loading";
    }

    //fetch Customers
    if (customers === undefined) {
        fetch(global.localip + "/customers/" + props.company_id, {headers: {"x-access-token": global.token}})
            .then(response => response.json())
            .then(responseJson => {
                setCustomers(responseJson);
            });
        return "Loading";
    }

    //fetch Wines
    if (wines === undefined) {
        fetch(global.localip + "/wines/" + props.company_id, {headers: {"x-access-token": global.token}})
            .then(response => response.json())
            .then(responseJson => {
                setWines(responseJson);
            });
        return "Loading";
    }

    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card plain>
                        <CardHeader plain color="primary">
                            <h4>
                                Dashboard
                            </h4>
                            <p>
                                A quick overview over your branch
                            </p>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={6}>
                                    <Card>
                                        <CardHeader color="warning" stats icon>
                                            <CardIcon color="warning">
                                                <Icon>dock</Icon>
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Total devices</p>
                                            <h3 className={classes.cardTitle}>
                                                {devices.length}
                                            </h3>
                                        </CardHeader>
                                    </Card>
                                </GridItem>
                                <GridItem xs={12} sm={6}>
                                    <Card>
                                        <CardHeader color="info" stats icon>
                                            <CardIcon color="info">
                                                <Icon>dock</Icon>
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Total customers</p>
                                            <h3 className={classes.cardTitle}>
                                                {customers.length}
                                            </h3>
                                        </CardHeader>
                                    </Card>
                                </GridItem>
                                <GridItem xs={12} sm={6}>
                                    <Card>
                                        <CardHeader color="success" stats icon>
                                            <CardIcon color="success">
                                                <Icon>dock</Icon>
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Total wines</p>
                                            <h3 className={classes.cardTitle}>
                                                {wines.length}
                                            </h3>
                                        </CardHeader>
                                    </Card>
                                </GridItem>
                                <GridItem xs={12} sm={6}>
                                    <Card>
                                        <CardHeader color="warning" stats icon>
                                            <CardIcon color="warning">
                                                <Icon>dock</Icon>
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Total pours</p>
                                            <h3 className={classes.cardTitle}>
                                                {ratings.length}
                                            </h3>
                                        </CardHeader>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    )
}

export default DashboardManager
