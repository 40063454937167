import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import MaterialTable from "material-table";
import ChartistGraph from "react-chartist";
import StarRatings from 'react-star-ratings';
import { Grid, MenuItem, Select } from '@material-ui/core';
import "./RatingList.css";

import { v4 as uuid4 } from 'uuid';

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);

export default function TableList(props) {
  const [select, setSelect] = React.useState("");

  const handleChange = (event) => {
    setSelect(event.target.value);
  };

  const wines = [...props.wines];


  return <>
    <Card plain>
      <CardHeader plain color="primary">
        <h4 className={styles.cardTitleWhite}>
          Ratings
        </h4>
        <p className={styles.cardCategoryWhite}>
          Select a wine to get a detailed rating overview.
        </p>
      </CardHeader>
      <CardBody>
        {props.customer_id === 0 && <>
          <Select
            value={select}
            onChange={handleChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            fullWidth
          >
            <MenuItem value="">
              <em>Please select a wine</em>
            </MenuItem>
            {props.wines.map(wine => {
              if (wine.company_id == props.company_id) {
                return (
                  <MenuItem key={uuid4()} value={wine.id}>{wine.sku}</MenuItem>
                );
              }
            })}
          </Select>
          <div style={{ margin: "20px" }}>
            {select != "" && <img src={global.getImage(global.wineObjbyWineId(select)).props.src} className="backend-statsimg"></img>}
            {select != "" && <ActualStatistics company_id={props.company_id} wineid={select}></ActualStatistics>}
          </div>
        </>}
        <TableList2 ratings={props.ratings} getRatingAverage={props.getRatingAverage}></TableList2>
      </CardBody>
    </Card>
  </>
}

function ActualStatistics(props) {
  var wineidprop = props.wineid;

  const [data, setData] = React.useState(undefined);
  const [wineid, setWineid] = React.useState(wineidprop);
  if (wineidprop !== wineid) {
    setWineid(wineidprop);
    setData(undefined);
  }
  if (data == undefined) {
    fetch(global.localip + "/poursCompany/" + props.company_id, { headers: { "x-access-token": global.token } })
      .then(response => response.json())
      .then(responseJson => {
        let toset = [];
        for (let i = 0; i < responseJson.length; i++) {
          const element = responseJson[i];
          if (element.wine_id == wineid) {
            toset.push(element);
          }
        }
        setData(toset);
      });
    return null;
  } else {
    var ratings = [];
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      if (ratings[element.rating_stars] == undefined) {
        ratings[element.rating_stars] = 1;
      } else {
        ratings[element.rating_stars] = ratings[element.rating_stars] + 1;
      }
    }
    for (let i = 0; i <= 5; i++) {
      const element = ratings[i];
      if (element == undefined) {
        ratings[i] = 0;
      }
    }

    // console.log(ratings);

    var chartdata = {
      labels: ['1 Stern', '2 Sterne', '3 Sterne', '4 Sterne', '5 Sterne'],
      series: [
        {
          value: ratings[1],
          name: "1 Stern"
        },
        {
          value: ratings[2],
          name: "2 Sterne"
        },
        {
          value: ratings[3],
          name: "3 Sterne"
        },
        {
          value: ratings[4],
          name: "4 Sterne"
        },
        {
          value: ratings[5],
          name: "5 Sterne"
        }
      ]
    }
    var options = {
      width: "100%",
      height: "250px",
      donut: false,
      labelInterpolationFnc: function (value) {
        return value[0]
      }
    }

    var responsiveOptions = [
      ['screen and (min-width: 640px)', {
        chartPadding: 30,
        labelOffset: 1000,
        labelDirection: 'explode',
        labelInterpolationFnc: function (value) {
          return value;
        }
      }],
      ['screen and (min-width: 1024px)', {
        labelOffset: 1000,
        chartPadding: 20
      }]
    ];

    return <div className="backend-stats">
      <Card style={{ backgroundColor: "#efefef" }}>
        <Grid container>
          <Grid item xs={6}>
            <p className="statstabletext">Ratings ({ratings[0] + ratings[1] + ratings[2] + ratings[3] + ratings[4] + ratings[5]})</p>
            <table className="statstable">
              <thead>
                <tr>
                  <td></td>
                  <td>Count</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    ★★★★★
                  </td>
                  <td>
                    {ratings[5]}
                  </td>
                </tr>
                <tr>
                  <td>
                    ★★★★☆
                  </td>
                  <td>
                    {ratings[4]}
                  </td>
                </tr>
                <tr>
                  <td>
                    ★★★☆☆
                  </td>
                  <td>
                    {ratings[3]}
                  </td>
                </tr>
                <tr>
                  <td>
                    ★★☆☆☆
                  </td>
                  <td>
                    {ratings[2]}
                  </td>
                </tr>
                <tr>
                  <td>
                    ★☆☆☆☆
                  </td>
                  <td>
                    {ratings[1]}
                  </td>
                </tr>
                <tr>
                  <td>
                    ∑
                  </td>
                  <td>
                    {ratings[0] + ratings[1] + ratings[2] + ratings[3] + ratings[4] + ratings[5]}
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
          <Grid item xs={4}>
            <ChartistGraph data={chartdata} options={options} type="Pie" responsiveOptions={responsiveOptions}></ChartistGraph>
            <p className="backchartavg">⌀ {((ratings[1] * 1 + ratings[2] * 2 + ratings[3] * 3 + ratings[4] * 4 + ratings[5] * 5) / (ratings[1] + ratings[2] + ratings[3] + ratings[4] + ratings[5])).toString().substring(0, 4)} ★</p>
          </Grid>
          <Grid item xs={2}>
            <table className="legendtable">
              <tr className="legend1">
                ★
              </tr>
              <tr className="legend2">
                ★★
              </tr>
              <tr className="legend3">
                ★★★
              </tr>
              <tr className="legend4">
                ★★★★
              </tr>
              <tr className="legend5">
                ★★★★★
              </tr>
            </table>
          </Grid>
        </Grid>
      </Card>
      <Card style={{ backgroundColor: "#efefef" }} className="statslast10wrapper">
        <table className="statslast10">
          <thead>
            <tr>
              <td>Date</td>
              <td>Customer ID</td>
              <td>Rating</td>
            </tr>
          </thead>
          <tbody>
            {
              data.slice(0, 10).map(entry => {
                return (
                  <tr>
                    <td>
                      {new Date(entry.created_at).toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric', hour: "2-digit", minute: "2-digit" })}
                    </td>
                    <td>
                      {entry.rating_user}
                    </td>
                    <td>
                      <Stars amount={entry.rating_stars}></Stars>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </Card>
    </div>
  }
}

function Stars(props) {
  var amount = props.amount;
  switch (amount) {
    case 0:
      return <>☆☆☆☆☆</>
      break;
    case 1:
      return <>★☆☆☆☆</>
      break;
    case 2:
      return <>★★☆☆☆</>
      break;
    case 3:
      return <>★★★☆☆</>
      break;
    case 4:
      return <>★★★★☆</>
      break;
    case 5:
      return <>★★★★★</>
      break;

    default:
      break;
  }
}


class TableList2 extends React.Component {
  constructor(props) {
    super(props);
    var rows = new Array;
    let ratingaverages = this.props.getRatingAverage();

    for (let i = 0; i < ratingaverages.length; i++) {
      const element = ratingaverages[i];
      if (element != undefined) {
        var total = 0;
        for (let i = 0; i < element.length; i++) {
          total = parseFloat(total) + parseFloat(element[i]);
        }
        var avg = total / element.length;
        var wine = global.wineObjbyWineId(i);
        avg = Math.round(avg * 100) / 100;
        if (isNaN(avg)) {
          avg = 0;
        }
        if (wine != undefined) {
          rows[rows.length] = { id: wine.id, sku: wine.sku, ean: wine.ean, origin_country: wine.origin_country, avg: avg }
        }
      }
    }

    this.state = {
      columns: [
        {
          title: "SKU",
          field: "sku"
        }, /*{
          title: "ID",
          field: "id"
        },*/ {
          title: "EAN",
          field: "ean"
        }, {
          title: "origin_country",
          field: "origin_country"
        }, {
          title: "Average",
          field: "avg",
          render: rowData => <StarRatings
            rating={rowData.avg}
            starRatedColor="yellow"
            numberOfStars={5}
            name='rating'
            starDimension="20px"
            starSpacing="0px"
          />
        }
      ],
      data: rows
    }
  }

  render() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card plain>
            <CardBody >
              <MaterialTable
                style={{ backgroundColor: "#efefef" }}
                columns={this.state.columns}
                data={this.state.data}
                title="Rating List"
                options={{
                  exportButton: true
                }}
                detailPanel={rowData => {
                  var ratings = new Array;
                  for (let i = 0; i < this.props.ratings.length; i++) {
                    const element = this.props.ratings[i];
                    var wineid = element.wine_id;
                    if (rowData.id == wineid) {
                      ratings[ratings.length] = element;
                    }
                  }
                  var columns = [
                    {
                      title: "Rating ID",
                      field: "id",
                      defaultSort: "desc"
                    }, {
                      title: "Customer",
                      field: "customer_id"
                    }, {
                      title: "Stars",
                      field: "rating"
                    }, {
                      title: "Time",
                      field: "updated",
                      render: rowData => <>{new Date(rowData.updated).toLocaleDateString('de-DE', { year: 'numeric', month: 'numeric', day: 'numeric', hour: "2-digit", minute: "2-digit" })}</>
                    }
                  ]

                  var rows = new Array;
                  var ratingsbyrate = [0, 0, 0, 0, 0, 0]
                  for (let i = 0; i < ratings.length; i++) {
                    const element = ratings[i];
                    ratingsbyrate[element.rating_stars] = ratingsbyrate[element.rating_stars] + 1
                    rows[rows.length] = { id: element.id, customer_id: element.rating_user, updated: element.updated_at, rating: element.rating_stars }
                  }

                  return (
                    <div>
                      <MaterialTable
                        columns={columns}
                        data={rows}
                        title={rowData.sku}
                        options={{
                          search: false,
                          paging: false,
                          sorting: true,
                          exportButton: true
                        }}
                      />
                    </div>
                  )
                }}

              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

}
