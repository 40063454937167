import React from 'react'
import Icon from '@material-ui/core/Icon';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle';
import { makeStyles } from '@material-ui/core/styles';
import { People, Business } from '@material-ui/icons';
import LocalBar from '@material-ui/icons/LocalBar';

const useStyles = makeStyles(styles);

function DashboardSuperadminCompanyRow(props) {
    const [branches, setBranches] = React.useState(undefined);
    const [devices, setDevices] = React.useState(undefined);
    const [wines, setWines] = React.useState(undefined);
    const [ratings, setRatings] = React.useState(undefined);
    const [customers, setCustomers] = React.useState(undefined);
    const classes = useStyles();

    const companydata = props.companydata;


    if (branches === undefined) {
        fetch(global.localip + "/branches/" + companydata.id, {headers: {"x-access-token": global.token}})
            .then(response => response.json())
            .then(responseJson => {
                setBranches(responseJson);
            });
        return "Loading";
    }

    if (devices === undefined) {
        fetch(global.localip + "/devices/0", {headers: {"x-access-token": global.token}})
            .then(response => response.json())
            .then(responseJson => {
                let validdata = [];
                for (let i = 0; i < responseJson.length; i++) {
                    const device = responseJson[i];
                    let branche_id = device.branche_id;
                    for (let i = 0; i < branches.length; i++) {
                        const branch = branches[i];
                        if (branch.id === branche_id) {
                            validdata.push(device);
                        }
                    }
                }
                setDevices(validdata);
            });
        return "Loading";
    }

    if (wines === undefined) {
        fetch(global.localip + "/wines/" + companydata.id, {headers: {"x-access-token": global.token}})
            .then(response => response.json())
            .then(responseJson => {
                setWines(responseJson);
            });
        return "Loading";
    }

    if (customers === undefined) {
        fetch(global.localip + "/customers/" + companydata.id, {headers: {"x-access-token": global.token}})
            .then(response => response.json())
            .then(responseJson => {
                setCustomers(responseJson);
            });
        return "Loading";
    }
    if (ratings === undefined) {
        fetch(global.localip + "/poursCompany/" + companydata.id, {headers: {"x-access-token": global.token}})
            .then(response => response.json())
            .then(responseJson => {
                setRatings(responseJson);
            });
        return "Loading";
    }

    return (
        <>
            <GridItem xs={12} sm={3}>
                <Card>
                    <CardHeader color="warning" stats icon>
                        <CardIcon color="warning">
                            <Business></Business>
                        </CardIcon>
                        <p className={classes.cardCategory}>Company</p>
                        <h4 className={classes.cardTitle}>
                            {companydata.name}
                        </h4>
                    </CardHeader>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={3}>
                <Card>
                    <CardHeader color="success" stats icon>
                        <CardIcon color="success">
                            <Icon>dock</Icon>
                        </CardIcon>
                        <p className={classes.cardCategory}>Branches / Devices</p>
                        <h4 className={classes.cardTitle}>
                            {branches.length} / {devices.length}
                        </h4>
                    </CardHeader>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={3}>
                <Card>
                    <CardHeader color="info" stats icon>
                        <CardIcon color="info">
                            <People></People>
                        </CardIcon>
                        <p className={classes.cardCategory}>Customers / Pours</p>
                        <h4 className={classes.cardTitle}>
                            {customers.length} / {ratings.length}
                        </h4>
                    </CardHeader>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={3}>
                <Card>
                    <CardHeader color="danger" stats icon>
                        <CardIcon color="danger">
                            <LocalBar></LocalBar>
                        </CardIcon>
                        <p className={classes.cardCategory}>Wines</p>
                        <h4 className={classes.cardTitle}>
                            {wines.length}
                        </h4>
                    </CardHeader>
                </Card>
            </GridItem>
        </>
    )
}

export default DashboardSuperadminCompanyRow
