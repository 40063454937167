import { CircularProgress } from '@material-ui/core';
import React from 'react'


export default function Logout() {
    //remove sessionStorage email and password to prevent automatic relogin
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("pw");
    //reload page
    window.location.reload(true);
    return (
        <div>
            <CircularProgress></CircularProgress>
        </div>
    )
}
