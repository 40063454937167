import React from 'react'

import AdminView from "./AdminView.js"
import { MenuItem, Select, Button, Grid } from '@material-ui/core';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import MaterialTable from 'material-table';
import { Alert } from 'reactstrap';


function SuperAdmin(props) {
    const [select, setSelected] = React.useState(0);
    const [companies, setCompanies] = React.useState(undefined);

    function fetchCompanies() {
        fetch(global.localip + "/companies", { headers: { "x-access-token": global.token } })
            .then(response => response.json())
            .then(responseJson => {
                setSelected(0);
                setCompanies(responseJson);
            });
    }

    if (companies == undefined) {
        fetchCompanies();

        return <>Loading Companies</>;
    }

    let selectedCompany = undefined;
    for (let i = 0; i < companies.length; i++) {
        const element = companies[i];
        if (element.id == select) {
            selectedCompany = element;
        }
    }

    // console.log(companies);

    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card plain>
                        <CardHeader plain color="primary">
                            <h4>
                                Companies
                            </h4>
                            <p>
                                Here you can select a company and manage it.
                            </p>
                        </CardHeader>
                        <CardBody>
                            <Grid container>
                                <Grid item xs={4}>
                                </Grid>
                                <Grid item xs={4}>
                                    <Select variant={'outlined'} fullWidth value={select} onChange={(e) => { setSelected(e.target.value) }}>
                                        <MenuItem value={0}><h4>No Company selected</h4></MenuItem>
                                        {companies.map((company, index) => (
                                            <MenuItem value={company.id} key={company.id}><h4>{company.name} ({company.id})</h4></MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item xs={4}>
                                </Grid>
                            </Grid>
                            <br></br>
                            <br></br>
                            {selectedCompany != undefined ?
                                <AdminView rank={props.rank} fetchCompanies={fetchCompanies} fetchWinesAndImages={props.fetchWinesAndImages} company={selectedCompany} wines={props.wines} images={props.images} key={selectedCompany.id}></AdminView> : null}
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    )
}

export default SuperAdmin

