import { Grid, TextField, Select, MenuItem, FormControl, InputLabel, Button, Snackbar } from '@material-ui/core';
import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';
var axios = require("axios");

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function DeviceSettings(props) {
    const [device, setDevice] = React.useState(undefined);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [snackbarMsg, setSnackbarMsg] = React.useState("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState("");

    const device_id = props.device_id;
    const branch_id = props.branch_id;

    function handleSnackbarClose() {
        setOpenSnackbar(false);
        setSnackbarMsg("");
    }

    function setSnackbar(open, msg, severity) {
        setOpenSnackbar(open);
        setSnackbarMsg(msg);
        setSnackbarSeverity(severity);
    }

    //ftech and set device
    if (device === undefined) {
        fetch(global.localip + "/devices/" + branch_id, { headers: { "x-access-token": global.token } })
            .then(response => response.json())
            .then(responseJson => {
                for (let i = 0; i < responseJson.length; i++) {
                    const element = responseJson[i];
                    if (element.id == device_id) {
                        setDevice(element);
                    }
                }
            });
        return "Loading";
    }

    //change field of branch obj
    function changefieldDevice(field, value) {
        let devicec = JSON.parse(JSON.stringify(device));
        devicec[field] = value;

        setDevice(devicec);
    }

    //update branch settings
    function changeSettings() {
        axios.post(global.localip + "/devices/" + device_id, {
            ...device, updated_at: undefined, created_at: undefined
        }, { headers: { "x-access-token": global.token } })
            .then((response) => {
                setSnackbar(true, "Devicesettings changed successfully", "success")
                // console.log(response);
            }, (error) => {
                setSnackbar(true, "Devicesettings error", "error")
                console.log(error);
            });
    }

    return (
        <Grid container spacing={4}>
            <Snackbar open={openSnackbar} autoHideDuration={10000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} >
                    {snackbarMsg}
                </Alert>
            </Snackbar>
            <Grid item xs={3}>
                <TextField variant="outlined" fullWidth label="Devicename" value={device.name} onChange={(e) => {
                    changefieldDevice("name", e.target.value);
                }}></TextField>
            </Grid>
            <Grid item xs={3}>
                <TextField variant="outlined" multiline fullWidth label="Description" value={device.description} onChange={(e) => {
                    changefieldDevice("description", e.target.value);
                }}></TextField>
            </Grid>
            <Grid item xs={12}>
                <Button color="primary" onClick={changeSettings} variant="outlined">Change DeviceSettings</Button>
            </Grid>
        </Grid>
    )
}

export default DeviceSettings
