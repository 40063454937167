import React from 'react'
import TableList from 'views/RatingList/RatingList';

function Ratings(props) {
    const company_id = props.company_id;
    const [ratings, setRatings] = React.useState(undefined);

    if(company_id == undefined){
        return "No Company ID";
    }
    
    //fetch Ratings
    if (ratings == undefined) {
        fetch(global.localip + "/poursCompany/" + company_id, {headers: {"x-access-token": global.token}})
            .then(response => response.json())
            .then(responseJson => {
                let toset = [];
                for (let i = 0; i < responseJson.length; i++) {
                    const element = responseJson[i];
                    if(props.customer_id == 0){
                        toset.push(element);
                    } else {
                        if(element.rating_user == props.customer_id){
                            toset.push(element);
                        }
                    }
                }
                setRatings(toset);
            });

        return <>Loading Ratings</>;
    }

    //rating averages arrray.
    //returns array of wine indexed by wineid
    function getRatingAverage() {
      var ratingaverages = new Array();
    
      for (let i = 0; i < ratings.length; i++) {
        const element = ratings[i];
        var wineid = element.wine_id;
    
        if (ratingaverages[wineid] == undefined) {
          ratingaverages[wineid] = new Array;
        }
        if(element.rating_stars !== 0 && element.rating_stars !== null){
            ratingaverages[wineid].push(element.rating_stars)
        }
      }
      return ratingaverages;
    }
    
    return (
        <div>
            <TableList company_id={company_id} getRatingAverage={getRatingAverage} images={props.images} wines={props.wines} ratings={ratings} customer_id={props.customer_id}></TableList>
        </div>
    )
}

export default Ratings
