import React from 'react'
import { Grid, Button } from '@material-ui/core';
import axios from 'axios';
import Backoffice from './Backoffice';
import DeviceSettings from 'views/DeviceSettings/DeviceSettings';
import TEXTS from "./languages.json";
import PoursDevice from 'views/RatingList/PoursDevice';

function Device(props) {
    const company_id = props.company_id;
    const device_id = props.device_id;
    const [fetchAll, setFetchAll] = React.useState(true);
    const [tapArray, setTapArray] = React.useState(undefined);
    const [cooler, setCooler] = React.useState(undefined);
    const [wines, setWines] = React.useState(undefined);
    const [pours, setPours] = React.useState(undefined);


    const API_PATH = global.localip + "/"


    if (fetchAll) {
        const requestCustomers = axios.get(API_PATH + 'customers/' + company_id, { headers: { "x-access-token": global.token } })
        const requestBranches = axios.get(API_PATH + 'branches/' + company_id, { headers: { "x-access-token": global.token } })
        const requestCooler = axios.get(API_PATH + 'cooler/' + device_id, { headers: { "x-access-token": global.token } })
        const requestWines = axios.get(API_PATH + 'wines/' + company_id, { headers: { "x-access-token": global.token } })
        const requestTaps = axios.get(API_PATH + 'taps/' + device_id, { headers: { "x-access-token": global.token } })
        const requestPours = axios.get(API_PATH + 'poursDevice/' + device_id, { headers: { "x-access-token": global.token } })

        axios.all([requestCustomers, requestBranches, requestCooler, requestWines, requestTaps, requestPours])
            .then(axios.spread((...responses) => {
                const responseCustomers = responses[0].data
                const responseBranches = responses[1].data
                const responesCooler = responses[2].data
                const responesWines = responses[3].data
                const responesTaps = responses[4].data
                const responesPours = responses[5].data

                let coolerArray = []
                for (const cool of responesCooler) {
                    if (cool.device_id === device_id) {
                        for (const tap of responesTaps) {
                            let countPours = 0
                            if (tap.device_id === device_id && tap.id === cool.tapL_id || tap.id === cool.tapR_id) {
                                for (const wine of responesWines) {
                                    if (tap.wine_id === wine.id) {
                                        tap.wine = wine
                                        for (const pour of responesPours) {
                                            if (pour.tap_id === tap.id && pour.wine_id === wine.id) {
                                                countPours = countPours + pour.pour_amount
                                            }
                                        }
                                        tap.counted_pours = countPours
                                        const isEmpty = (Math.floor((tap.bottle_ml - tap.residue) / tap.pour_amount)) - (Math.floor((countPours) / tap.pour_amount))
                                        tap.isEmpty = isEmpty
                                        coolerArray.push(tap)
                                    }
                                }
                            }
                        }
                    }
                }
                coolerArray.sort((a, b) => a.side - b.side);
                coolerArray.sort((a, b) => a.cooler_id - b.cooler_id);

                setTapArray(coolerArray)
                setCooler(responesCooler)
                setWines(responesWines)
                setPours(responesPours)
                setFetchAll(false)
            })).catch(errors => {
                // errors.
                console.log(errors);
            })
        return "Loading...";
    }

    return (<>
        <Grid container>
            <Grid item xs={12}>
                <Backoffice
                    setFetchAll={setFetchAll}
                    pours={pours}
                    wines={wines}
                    cooler={cooler}
                    setCooler={setCooler}
                    tapArray={tapArray}
                    setTapArray={setTapArray}
                    API_PATH={API_PATH}
                    TEXTS={TEXTS}
                    currentLang="de"
                    calibration_time={5}
                    company_id={company_id}
                    device_id={device_id}
                />
            </Grid>
            <Grid item xs={12}>
                <PoursDevice pours={pours}></PoursDevice>
                <br></br>
                <br></br>
            </Grid>
            <Grid item xs={12}>
                <DeviceSettings device_id={device_id} branch_id={props.branch_id}></DeviceSettings>
            </Grid>
            <Grid item xs={12}>
                {props.rank === 3 ?
                    <Button variant="outlined" color="secondary" onClick={() => {
                        if (window.confirm("Are you sure to delete the device?")) {
                            if (window.confirm("Are you really really sure to delete the device?")) {
                                axios.delete(global.localip + "/devices/" + device_id, { headers: { "x-access-token": global.token } })
                                    .then((response) => {
                                        if (props.fetchDevices !== undefined) {
                                            props.fetchDevices();
                                        }
                                    }, (error) => {
                                        console.log(error);
                                    });
                            }
                        }
                    }}>Delete Device</Button>
                    : null}
            </Grid>
        </Grid>
    </>)
}

export default Device
