import React from 'react'
import DashboardAdmin from './DashboardAdmin'
import DashboardExecutive from './DashboardExecutive'
import DashboardManager from './DashboardManager'
import DashboardSuperadmin from './DashboardSuperadmin'

function Dashboard(props) {
    if(props.rank === 3){
        return <DashboardSuperadmin rank={props.rank} company_id={props.company_id} branch_id={props.branch_id}></DashboardSuperadmin>
    }
    if(props.rank === 2){
        return <DashboardAdmin rank={props.rank} company_id={props.company_id} branch_id={props.branch_id}></DashboardAdmin>
    }
    if(props.rank === 1){
        return <DashboardManager rank={props.rank} company_id={props.company_id} branch_id={props.branch_id}></DashboardManager>
    }
    if(props.rank === 0){
        return <DashboardExecutive wines={props.wines} images={props.images} rank={props.rank} company_id={props.company_id} branch_id={props.branch_id}></DashboardExecutive>
    }

    return (
        <div>
        rank: {props.rank}<br></br>
        company: {props.company_id}<br></br>
        branch: {props.branch_id}
        </div>
    )
}

export default Dashboard
